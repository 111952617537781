<template>
   <div
              class="border border-gray-300 dark:border-gray-500 rounded-r-full flex items-center justify-center space-x-1 px-1 h-6 min-w-12 text-[1rem] align-middle"
              @click.stop.prevent="openSharePopover($event)"
              >
  <div
    title=" rePost"
    :id="['popover-Share' + post.id]"
    
    class="justify-center items-center flex"
  >
    <ion-icon
      v-if="globalStore.isParler"
      :src="repostIcon"
      :class="{
        'user-has': hasReposts(),
      }"
      class="text-sm"
    />
    <ion-icon
      v-else
      :class="{
        'user-has': hasReposts(),
        'text-lg': isCommentDetails || globalStore.isParler,
      }"
      :icon="repeatSharp"
      class="text-2xl cursor-pointer select-none"
    />
  </div>
  <FormatCount
    v-if="post?.postEngagement?.repostCount > 0"
    :count="post?.postEngagement?.repostCount"
    class="text-sm"
    :class="hasReposts() ? 'user-has' : ''"
  />
  <ion-popover
    :is-open="popoverShareOpen"
    :arrow="false"
    :event="share"
    @didDismiss="popoverShareOpen = false"
    side="top"
    alignment="right"
  >
    <ion-list nested lines="none">
      <ion-item @click.prevent="rePost(post)" class="cursor-pointer select-none">
        <p
          v-if="post?.userEngagement.hasReposted"
          class="user-has flex justify-center items-center space-x-2"
        >
          <ion-icon
            v-if="globalStore.isParler"
            :src="repostIcon"
            class="text-sm user-has"
          />
          <ion-icon v-else :icon="repeatSharp" class="w-7 text-xl" />
          <span>Repost <sup>🆇</sup></span>
        </p>
        <p v-else class="text-close flex space-x-2 justify-center items-center">
          <ion-icon v-if="globalStore.isParler" :src="repostIcon" class="text-sm" />
          <ion-icon v-else :icon="repeatSharp" class="w-7 text-xl" />
          <span> Repost </span>
        </p>
      </ion-item>
      <ion-item @click.prevent="showRepostQuoteForm" class="cursor-pointer select-none">
        <img
          src="@/assets/quotes.svg"
          class="w-6 -mb-2 dark:filter dark:invert"
          alt="quote"
        />
        <p class="ml-2">Quote</p>
      </ion-item>
    </ion-list>
  </ion-popover>
  <create-post-popup
    v-if="createPostOpen"
    :is-opened="createPostOpen"
    :is-repost="true"
    @close="hideRepostQuoteForm"
    :original-post="post"
  />
  </div>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from "vue";
import { useGlobalStore } from "@/stores/global";
import { usePostStore } from "@/stores/post";
import { apiBackendAuthAxios } from "@/axiosAuth";
import { IonIcon, IonPopover, IonItem, IonList } from "@ionic/vue";
import { repeatSharp } from "ionicons/icons";
import CreatePostPopup from "@/components/CreatePostPopup.vue";
import FormatCount from "@/components/FormatCount.vue";
import repostIcon from "@/assets/parler/repost_icon.svg";

const emit = defineEmits(["showToast"]);
const postStore = usePostStore();
const globalStore = useGlobalStore();
const props = defineProps({
  parentItem: Object,
  detailsShow: {
    type: Boolean,
    default: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
  },
  repostId: {
    type: String,
    default: "",
  },
});

const post = computed(() => {
  return postStore.postsCache[props.parentItem.id];
});

function showRepostQuoteForm() {
  createPostOpen.value = true;
  popoverShareOpen.value = false;
}

const hideRepostTimeout = ref(null);

function hideRepostQuoteForm() {
  hideRepostTimeout.value = setTimeout(() => {
    createPostOpen.value = false;
  }, 300);
}

const hasReposts = () => {
  return (
    post.value.userEngagement?.hasReposted ||
    post.value.userEngagement?.hasRepostedWithComment
  );
};

const popoverShareOpen = ref(false);
let share = null;

const openSharePopover = (e) => {
  e.preventDefault();
  share = e;
  popoverShareOpen.value = true;
};

// Function to display the toast
function showToast(message, color) {
  emit("showToast", { message, color });
}

function createRePost(rePostData, parentItem) {
  postStore
    .createRePost(rePostData, parentItem)
    .then(async (post) => {
      // Display the toast message for successful repost
      showToast("Your repost has been recorded.", "success");
    })
    .catch((error) => {
      console.error(error);
      // Display an error message to the user
      showToast("An unexpected error occurred.", "danger");
    });
}

async function rePost(parentItem) {
  const rePostData = {
    ulid: parentItem.id,
    title: "repost",
  };
  if (
    parentItem.userEngagement.hasReposted ||
    parentItem.userEngagement.hasRepostedWithComment
  ) {
    apiBackendAuthAxios
      .delete("/posts/repost", { data: rePostData })
      .then((response) => {
        // Display the toast message for successful repost
        //update parentItem
        postStore.postsCache[parentItem.id].userEngagement.hasReposted = false;
        // remove repost from cache and feedmaps
        if (props.repostId) {
          postStore.removePostFromCache(props.repostId);
        } else {
      // Find the repost in the cache and remove it
      const repostKey = Object.keys(postStore.postsCache).find((key) => {
        const post = postStore.postsCache[key];
        return (
          post.parentUlid === parentItem.id &&
          post.isRepost === true &&
          post.isRepostWithComment === false
        );
      });
      if (repostKey) {
        postStore.removePostFromCache(repostKey);
      }
    }
        showToast("Your repost has been removed.", "success");
      })
      .catch((error) => {
        console.error(error);
        // Display an error message to the user
        showToast("An unexpected error occurred.", "danger");
      })
      .finally(() => {
        showToast("Repost deleted ", "success");
      });
  } else {
    createRePost(rePostData, parentItem);
  }
  popoverShareOpen.value = false; // Assuming popoverOpenedUp controls the visibility of the popover
}
const createPostOpen = ref(false);

onBeforeUnmount(() => {
  if (createPostOpen.value) {
    createPostOpen.value = false;
  }

  if (hideRepostTimeout.value) {
    clearTimeout(hideRepostTimeout.value);
  }

  if (popoverShareOpen.value) {
    popoverShareOpen.value = false;
  }
});
</script>

<style scoped>
ion-icon {
  --ionicon-stroke-width: 56px;
}
ion-popover {
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --width: 135px;
}

.user-has {
  color: var(--tertiary-color);
}
</style>
