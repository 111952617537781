<template id="reaction-icon">
  <ion-icon
    :title="title"
    @click="emitClick"
    :src="
      (isReactionNegative && userReaction && rotation === 180) ||
      (isReactionPositive && userReaction && rotation === 0)
        ? voteArrow
        : voteArrowOutline
    "
    :class="{
      'rotate-180': rotation === 180,
      'down-vote': isReactionNegative && userReaction && rotation === 180,
      'up-vote': isReactionPositive && userReaction && rotation === 0,
    }"
    class="cursor-pointer select-none"
  />
</template>

<script setup>
import {} from "vue";
import { IonIcon } from "@ionic/vue";
import voteArrow from "@/assets/parler/voteArrow.svg";
import voteArrowOutline from "@/assets/parler/voteArrowOutline.svg";

defineProps([
  "title",
  "icon",
  "rotation",
  "active",
  "userReaction",
  "isReactionPositive",
  "isReactionNegative",
]);
const emit = defineEmits(["click"]);

const emitClick = () => {
  emit("click");
};
</script>
<style scoped>
.up-vote {
  color: #0084ff;
}
.down-vote {
  color: #fec84b;
}
</style>
