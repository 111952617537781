export const randomInsert = (originalArray, insertArray, min, max) => {
  // Directly use the originalArray and insertArray as they're already arrays of ULIDs

  // Return originalArray if it has fewer than 10 items
  if (originalArray.length < 10) {
    return originalArray;
  }

  // Clone the originalArray to avoid modifying the original
  let tempFeed = [...originalArray];

  // Calculate the range of possible insertion indices
  let indexRange = tempFeed.length - min;

  // Iterate over the insertArray to randomly insert each ULID into the tempFeed
  insertArray.forEach((ulid) => {
    let randomIndex = Math.floor(Math.random() * (indexRange + 1) + min);
    tempFeed.splice(randomIndex, 0, ulid);
  });

  return tempFeed;
};
