<template>
    <div class="logo-wrapper">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px" y="0px" viewBox="0 0 960 960" style="enable-background:new 0 0 960 960;" xml:space="preserve">
            <defs>
                <linearGradient :id="gradientId1" gradientUnits="userSpaceOnUse" x1="5.7441" y1="479.6618" x2="955"
                    y2="479.6618">
                    <stop offset="0" :style="{ 'stop-color': primaryColor }" />
                    <stop offset="1" :style="{ 'stop-color': secondaryColor }" />
                </linearGradient>
                <linearGradient :id="gradientId2" gradientUnits="userSpaceOnUse" x1="70.5122" y1="479.6467" x2="890.3226"
                    y2="479.6467">
                    <stop offset="0" :style="{ 'stop-color': primaryColor }" />
                    <stop offset="1" :style="{ 'stop-color': secondaryColor }" />
                </linearGradient>
            </defs>
            <g>
                <path :fill="'url(#' + gradientId1 + ')'" d="M5.74,479.37C9.42,215.28,214.84,2,484.29,4.01C746.71,5.98,954.69,212.9,955,479.23
		c0.31,267.8-210.19,475.69-472.83,476.09C209.52,955.73,8.81,739.13,5.74,479.37z M925.94,480.24
		c4.74-241.34-196.64-443.8-436.92-447.65c-249.26-4-452.04,201.97-453.4,444.21C34.23,723.64,238.5,927.04,481.81,926.79
		C726.95,926.53,930.31,721.37,925.94,480.24z" />

                <path :fill="'url(#' + gradientId2 + ')'" d="M890.27,479.72
		c3.5,220.8-181.94,411.78-410.12,411.59c-225.24-0.19-410.06-188.2-409.64-412.5C70.94,255.31,255.53,68.08,480.3,67.98
		C708.41,67.87,893.85,258.88,890.27,479.72z M387.96,537.94c-1.19,0.03-2.38,0.07-3.57,0.1c-6.61-18.8-13-37.68-19.89-56.38
		c-11.28-30.58-22-61.42-34.7-91.41c-7.98-18.84-25-26.98-45.09-27.18c-20.4-0.2-35.27,9.19-44.8,27.59
		c-10.25,19.79-3.66,38.01,3.76,56.43c17.25,42.83,34.49,85.67,51.57,128.57c9.46,23.77,18.61,47.67,27.88,71.51
		c9.72,24.98,26.38,41.47,54.42,42.83c27.05,1.31,46.54-12.25,59.23-40.35c3.3-7.31,6.14-14.84,9.05-22.32
		c9.93-25.57,19.8-51.16,30.8-79.61c3.33,7.43,5.47,11.8,7.27,16.31c12.3,30.74,22.76,62.36,37.21,92.05
		c21.31,43.78,78.75,46.34,104.36,5.85c4.53-7.16,8.05-15.08,11.16-22.99c26.78-68.2,53.53-136.41,79.9-204.76
		c7.28-18.88,7.13-37.46-7.32-53.66c-25.74-28.86-69.87-23.78-86.29,11.47c-11.4,24.46-19.23,50.6-28.36,76.1
		c-8.32,23.23-16.24,46.6-25.68,73.77c-12.56-31.53-21.84-59.25-34.68-85.22c-6.55-13.24-17.44-26.7-29.9-34.19
		c-33.36-20.05-71.06-2.9-86.56,37.01C407.59,485.55,397.87,511.78,387.96,537.94z M477.65,250.77
		c-26.79-0.12-50.88,24.07-50.99,51.2c-0.11,26.82,24.41,50.69,51.68,50.29c27-0.4,49.11-23.37,49.12-51.04
		C527.46,274.32,504.33,250.89,477.65,250.77z" />
            </g>
        </svg>
    </div>
</template>
<script setup>
import { computed, ref, onMounted, onUnmounted } from "vue";
import { useGlobalStore } from "@/stores/global";

const globalStore = useGlobalStore();

const gradientId1 = ref('SVGID_1_');
const gradientId2 = ref('SVGID_2_');

onMounted(() => {
    gradientId1.value = 'SVGID_' + Math.random().toString(36).substr(2, 9);
    gradientId2.value = 'SVGID_' + Math.random().toString(36).substr(2, 9);
});

onUnmounted(() => {
    gradientId1.value = 'SVGID_1_';
    gradientId2.value = 'SVGID_2_';
});
const getCssVariable = (variable) => {
    return getComputedStyle(document.documentElement).getPropertyValue(variable).trim();
};

const primaryColor = computed(() => {
    return globalStore.primary_colors || getCssVariable('--primary-color');
});

const secondaryColor = computed(() => {
    return globalStore.secondary_colors || getCssVariable('--secondary-color');
});

</script>
<style scoped>
.logo-wrapper svg {
    width: 100%;
    /* or any other size */
    height: auto;
    max-width: 150px;
    /* or any other max size */
}
</style>