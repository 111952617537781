<template>
  <div
    ref="playerContainer"
    :class="'aspect-w-16 aspect-h-9'"
    :style="{ height: height ? `${height}px` : '' }"
  >
    <iframe
        :id="postData.id + '-embed'"
        :src="`${postData.embedUrl}?autoplay=1&api=1&player_id=${postData.id}-embed`"
        width="100%"
        height="100%"
        allow="autoplay; fullscreen; picture-in-picture"
        allowfullscreen
        title=""
      ></iframe>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import Player from "@vimeo/player";
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  postData: {
    type: Object,
    required: true,
  },
  height: {
    type: Number,
    default: 0,
  },
  inCarousel: {
    type: Boolean,
    default: false,
  },
});

const global = useGlobalStore();
const playerContainer = ref(null);
const vimeoPlayer = ref(null);

const emit = defineEmits(["destroyed"]);

let containerObserver;

const onVisibilityChange = (entries) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting && vimeoPlayer.value) {
      destroyVimeoPlayer();
      emit("destroyed");
    }
  });
};

onMounted(() => {
  // Create an intersection observer instance
  containerObserver = new IntersectionObserver(onVisibilityChange, {
    root: null, // relative to the viewport
    rootMargin: "0px",
    threshold: 0.1, // 10% of the item's visible area is enough to consider it as "not in view"
  });

  // Observe the target element
  if (playerContainer.value) {
    containerObserver.observe(playerContainer.value);
  }

  // Initialize the Vimeo player
  if (props.postData.embedUrl.startsWith("https://player.vimeo") || props.postData.embedUrl.startsWith("https://vimeo")) {
    vimeoPlayer.value = new Player(props.postData.id + "-embed");
    
    vimeoPlayer.value.on("play", () => {
      global.playingVideoId = props.postData.id + "-embed";
    });

    vimeoPlayer.value.on("pause", () => {
      if (global.playingVideoId === props.postData.id + "-embed") {
        global.playingVideoId = null;
      }
    });

    vimeoPlayer.value.on("ended", () => {
      if (global.playingVideoId === props.postData.id + "-embed") {
        global.playingVideoId = null;
      }
    });
  }
});

onBeforeUnmount(() => {
  // Disconnect the observer
  if (containerObserver && playerContainer.value) {
    containerObserver.unobserve(playerContainer.value);
  }

  // Destroy the Vimeo player
  destroyVimeoPlayer();
});

watch(
  () => global.playingVideoId,
  (newVal) => {
    if (newVal !== props.postData.id + "-embed" && vimeoPlayer.value) {
      vimeoPlayer.value.pause();
    }
  }
);

watch(
  () => props.postData,
  (newVal, oldVal) => {
    // Assuming the video should be stopped when new post data is received
    if (oldVal && newVal.id !== oldVal.id && vimeoPlayer.value) {
      vimeoPlayer.value.pause();
    }
  },
  { deep: true }
);

const destroyVimeoPlayer = () => {

  if (vimeoPlayer.value && vimeoPlayer.value.element) {
    vimeoPlayer.value.unload().then(() => {
      vimeoPlayer.value = null;
    }).catch((error) => {
      console.warn("Error unloading Vimeo player:", error);
    });
  }
};
</script>

<style scoped>
.player {
  border: none;
  border-radius: 0.5rem !important;
}
</style>
