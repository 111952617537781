<template>
  <transition name="avatar-menu">
    <div v-if="avatarMenu" class="fixed inset-0 z-50">
      <!-- Backdrop -->
      <div
        class="absolute inset-0 bg-black transition-opacity duration-300 cursor-pointer select-none"
        :class="{ 'opacity-50': avatarMenu, 'opacity-0': !avatarMenu }"
        @click="toggleAvatarMenu"
      ></div>

      <!-- Drawer -->
      <div class="menu absolute left-0 top-0 bottom-0 shadow-lg flex">
        <div class="w-[60vw] bg-white dark:bg-[#1c1c1d]">
          <!-- Drawer content -->
          <avatar-popover
            @open="feedbackModalOpen = true"
            @openInvite="inviteModalOpen = true"
            @close="toggleAvatarMenu"
            :avatar="avatar"
          />
        </div>
        <div class="right-border w-[10vw]"></div>
      </div>
    </div>
  </transition>
  <transition name="slide-toolbar">
    <div v-if="showToolBar" class="pl-3 pr-2 toolbar-container">
      <div
        class="toolbar"
        :style="{ backgroundImage: 'url(' + bgImage + ')' }"
      ></div>
      <div
        class="z-10 absolute bottom-2 flex items-center justify-between w-[98%] text-[#525252] dark:text-white"
      >
        <div class="w-1/2">
          <ion-buttons
            class="flex justify-evenly items-center cursor-pointer select-none"
          >
            <div
              class="flex flex-col items-center pt-3"
              :class="isHome ? 'active' : ''"
              @click="navigatoToHome"
            >
              <HomeIcon :active="isHome" @click="homeClick" />
              <p :class="isHome ? 'active-text' : ''" class="text-xs font-bold">
                Home
              </p>
            </div>
            <div
              class="flex flex-col items-center pt-3"
              :class="isSearch ? 'active' : ''"
              @click="navigateToSearch"
            >
              <SearchIcon :active="isSearch" />
              <p
                :class="isSearch ? 'active-text' : ''"
                class="text-xs font-bold"
              >
                Search
              </p>
            </div>
          </ion-buttons>
        </div>
        <div class="w-1/4"></div>

        <div
          class="absolute right-1/2 bottom-0 translate-x-1/2 mb-3 cursor-pointer select-none"
          @click="openCreatePost"
          :class="{
            'ring-8 rounded-full ring-red-500 ring-opacity-50':
              authStore.currentUser.postCount === 0,
          }"
        >
          <ion-icon
            @click="openCreatePost"
            :src="isGroupPage && isJoined ? groupIcon : post_button"
            class="create-post-button p-3 mb-0 md:p-4 md:mb-0 text-4xl md:text-6xl border-4 border-white dark:border-[#434343]"
          />
        </div>
        <div
          class="w-1/2 flex justify-evenly items-center text-2xl cursor-pointer select-none"
        >
          <div
            class="flex flex-col items-center pt-3"
            :class="isGroups ? 'active' : ''"
            @click="navigateToGroups"
          >
            <GroupIcon :active="isGroups" />
            <p :class="isGroups ? 'active-text' : ''" class="text-xs font-bold">
              Groups
            </p>
          </div>
          <div
            class="flex flex-col items-center mt-2 pb-2 pt-3.5 mr-5"
            :class="isProfile ? 'active' : ''"
            @click="toggleAvatarMenu"
          >
            <ion-avatar :disabled="modalOpen" class="avatar aspect-1 h-6 w-6">
              <UserAvatar :avatar="avatar" />
            </ion-avatar>

            <p
              :class="isProfile ? 'active-text' : ''"
              class="text-xs font-bold"
            >
              Profile
            </p>
          </div>
        </div>
        <FeedbackModal
          :is-opened="feedbackModalOpen"
          @close="feedbackModalOpen = false"
          @showToast="handleShowToast"
        />
        <InviteModal
          :is-opened="inviteModalOpen"
          @closeInvite="inviteModalOpen = false"
        />
      </div>
    </div>
  </transition>
  <CreatePostPopup
    v-if="createPostOpen"
    :is-opened="createPostOpen"
    @close="handleCreatePostPopupClose"
    @timeout="handleTimeout"
    :sharedFiles="sharedFiles"
    :shareUrl="shareUrl"
  />
  <UserOnboarding
    v-if="authStore.currentUser.postCount === 0"
    @onboardingComplete="completeOnboarding"
    :firstPost="true"
    :showToolBar="showToolBar"
  />
  <ion-toast
    :is-open="toastOpen"
    :message="toastMessage"
    :duration="toastDuration"
    :color="toastColor"
  />
</template>
<script setup>
import { IonIcon, IonButtons, IonAvatar, IonToast } from "@ionic/vue";
import { trendingUpOutline } from "ionicons/icons";
import SearchIcon from "@/components/tenantcomponents/parler/icons/SearchIcon.vue";
import HomeIcon from "@/components/tenantcomponents/parler/icons/HomeIcon.vue";
import GroupIcon from "@/components/tenantcomponents/parler/icons/GroupIcon.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import post_button from "@/assets/parler/post_button.svg";
import groupIcon from "@/assets/parler/group_icon.svg";
import CreatePostPopup from "@/components/CreatePostPopup.vue";
import UserOnboarding from "@/components/UserOnboarding.vue";
import {
  ref,
  computed,
  onMounted,
  onUnmounted,
  watch,
  onBeforeUnmount,
} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAuthStore } from "@/stores/auth";
import { useEventBus } from "@vueuse/core";
import FeedbackModal from "@/components/FeedbackModal.vue";
import InviteModal from "@/components/InviteModal.vue";
import AvatarPopover from "@/components/AvatarPopover.vue";
import { useGlobalStore } from "@/stores/global";
import toolbarBg from "@/assets/parler/toolbar_bg.svg";
import toolbarBgDark from "@/assets/parler/toolbar_bg_dark.svg";

const authStore = useAuthStore();
const globalStore = useGlobalStore();
const route = useRoute();
const router = useRouter();
const modalOpen = ref(false);
const feedbackModalOpen = ref(false);
const inviteModalOpen = ref(false);
const avatarMenu = ref(false);
const avatar = ref(authStore.currentUser.avatar);
const isHome = ref(route.path === "/feed");
const isSearch = ref(route.path === "/search" || route.name === "Search");
const isGroups = ref(route.path === "/groups");
const isProfile = ref(route.path === `/${authStore.currentUser.username}`);
const toastMessage = ref("");
const toastDuration = ref(0);
const toastColor = ref("");
const toastOpen = ref(false);
const shareUrl = ref(route.query["share-url"] || "");

const timeoutError = ref(false);
const timeoutUntil = ref("");
const timeoutAction = ref("");

const isGroupPage = ref(route.name === "Group");

const sharedFiles = ref([]);

const props = defineProps({
  currentFeed: {
    type: String,
    required: false,
  },
  showToolBar: {
    type: Boolean,
    required: false,
    default: true,
  },
  isJoined: {
    type: Boolean,
    required: false,
    default: false,
  },
});

onMounted(() => {
  if (shareUrl.value) {
    createPostOpen.value = true;
  }
});

// Watch for route updates to check for the share-url
watch(
  () => route.query,
  (newQuery) => {
    if (newQuery["share-url"]) {
      shareUrl.value = newQuery["share-url"];
      createPostOpen.value = true;
    }
  }
);

onUnmounted(() => {});

const handleShowToast = (payload) => {
  showToast(payload.message, payload.color);
};

function showToast(message, color) {
  toastMessage.value = message;
  toastDuration.value = 2000;
  toastColor.value = color;
  toastOpen.value = !toastOpen.value;
}

const bgImage = computed(() => {
  return globalStore.settings.dark_mode ? toolbarBgDark : toolbarBg;
});

const emit = defineEmits(["updateFeedType", "refreshFeed", "scroll-to-top"]);

const setFeedType = () => {
  if (globalStore.settings.default_feed) {
    globalStore.currentFeed = globalStore.settings.default_feed;
  } else {
    globalStore.currentFeed = "following";
  }
};

const handleTimeout = (payload) => {
  timeoutError.value = true;
  timeoutUntil.value = payload.timeoutUntil;
  timeoutAction.value = payload.action;
};

const closeTimeoutAlert = () => {
  timeoutError.value = false;
  timeoutUntil.value = "";
  timeoutAction.value = "";
};

function homeClick() {
  globalStore.currentFeed = globalStore.settings.default_feed;
  // if route.path is not '/' then redirect to '/' else emit event
  if (route.path !== "/feed") {
    router.push("/feed");
    return;
  }
  emit("scroll-to-top");

  setFeedType();
}

const navigatoToHome = () => {
  router.push("/feed");
};

const navigateToSearch = () => {
  const option = route.path === "/search" ? "reset" : undefined;
  // router.push({ path: "/search", params: { option } });
  router.push({ path: "/search", query: { option } });

  if (route.path === "/search") {
  } else {
    router.push("/search");
  }
};

const navigateToGroups = () => {
  router.push("/groups");
};

// Watch for route changes
watch(
  () => route.path,
  () => {
    // Close the avatar menu when the route changes
    avatarMenu.value = false;
  }
);

const toggleAvatarMenu = () => {
  avatarMenu.value = !avatarMenu.value;
};

const onBoardingFollows = computed(() => {
  if (authStore.currentUser.following < 5) {
    return 5 - authStore.currentUser.following;
  } else {
    return 0;
  }
});

function openCreatePost() {
  createPostOpen.value = true;
}

const modalBus = useEventBus("modalBus");
modalBus.on(() => {
  modalOpen.value = !modalOpen.value;
});

const hideTimeout = ref(null);

function handleCreatePostPopupClose() {
  hideTimeout.value = setTimeout(() => {
    createPostOpen.value = false;
  }, 300);
}

function handleFeedTypeSelected(feedType) {
  if (route.path !== "/feed") {
    // Navigate to the home view ("/feed") with the specified feedType
    router.push({ path: "/feed", query: { feedType } });
  } else {
    emit("updateFeedType", feedType);
  }
}

const createPostOpen = ref(false);

function completeOnboarding() {
  authStore.currentUser.accountSetup = true;
}

onBeforeUnmount(() => {
  if (hideTimeout.value) {
    clearTimeout(hideTimeout.value);
  }
  if (modalOpen.value) {
    modalBus.emit();
  }
  if (feedbackModalOpen.value) {
    feedbackModalOpen.value = false;
  }
  if (inviteModalOpen.value) {
    inviteModalOpen.value = false;
  }
  if (avatarMenu.value) {
    avatarMenu.value = false;
  }
  if (createPostOpen.value) {
    createPostOpen.value = false;
  }
  if (toastOpen.value) {
    toastOpen.value = false;
  }
});
</script>

<style scoped>
/* SVG Styling */
.dark-svg {
  stroke: white !important;
}

ion-icon {
  --ionicon-stroke-width: 30px;
}

.active-text {
  color: var(--primary-color);
}

.active {
  position: relative;
  /* Needed to position the pseudo-element */
  color: var(--primary-color);
}

ion-icon .active {
  color: var(--primary-color);
}

.active::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 5px;
  /* Align with the top of the toolbar item */
  width: 60%;
  /* Full width of the icon or as needed */
  height: 3px;
  /* Height of the active indicator */
  background-color: var(--primary-color);
  /* Your active color */
  z-index: 10;
}

.right-border {
  background: linear-gradient(
    to bottom right,
    var(--primary-color, --secondary-color),
    var(--secondary-color, --primary-color)
  );
}

.create-post-button {
  color: white;
  /* Icon color */
  background: var(--primary-color);
  /* Your button background color */
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  /* Circular shape */
  /* Adjust padding to control the size of the button */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  /* Drop shadow for the button */
  /* Center the icon if it's not centered */
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-icons,
.right-icons {
  position: absolute;
  bottom: 25px;
  /* Adjust this value to position the icons inside the curved bar */
  /* The rest of your styles */
}

.left-icons {
  left: 40px;
  /* Adjust as necessary */
}

.right-icons {
  right: 40px;
  /* Adjust as necessary */
}

.avatar {
  padding: 1px;
  /* Border width */
  border-radius: 50%;
}

.active .avatar {
  background: linear-gradient(
    to right,
    var(--primary-color),
    var(--secondary-color)
  );
}

.avatar img {
  display: block;
  border-radius: 50%;
}

.toolbar-container {
  position: relative;
  /* This creates the positioning context for .toolbar */

  /* This ensures that the toolbar slides in and out of view */
}

/* Update the transition states */
.slide-toolbar-enter-active,
.slide-toolbar-leave-active {
  transition: transform 0.3s ease;
}

.slide-toolbar-enter-from,
.slide-toolbar-leave-to {
  /* This moves the toolbar down by its own height to hide it */
  transform: translateY(3.3rem);
  /* Adjust the value to match toolbar height */
}

.slide-toolbar-enter-to,
.slide-toolbar-leave-from {
  /* This ensures the toolbar is in view */
  transform: translateY(0);
}

.toolbar {
  position: absolute;
  bottom: 0;
  width: 94%;
  /* Adjust width as needed */
  height: 4rem;
  /* Adjust height as needed */
  /* Adjust color as needed */
  border-radius: 10px 10px 0 0;
  /* Rounded top edges */
  box-shadow: 4 -4px 20px rgba(0, 0, 0, 0.2);
  /* Shadow on top */
  z-index: 1;
  /* Ensures it's under the post button */
  box-shadow: 4px 4px 10px 2px rgba(0, 0, 0, 0.2);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.avatar-menu-enter-active,
.avatar-menu-leave-active {
  transition: transform 0.3s ease;
}

.avatar-menu-enter-from,
.avatar-menu-leave-to {
  transform: translateX(-100%);
}

.avatar-menu-enter-to,
.avatar-menu-leave-from {
  transform: translateX(0);
}
</style>
