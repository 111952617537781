<template>
  <div ref="target" class="has-embed">
    <div class="postIframe" :style="{ height: isBitchute ? '360px' : 'auto' }">
      <!-- Thumbnail -->
      <div
        v-if="isYoutube && !playerReady"
        class="w-full cursor-pointer select-none relative aspect-w-16 aspect-h-9"
        :class="
          inCarousel
            ? 'h-full flex justify-center'
            : 'relative aspect-w-16 aspect-h-9'
        "
        @click.stop.prevent="initPlayer"
      >
        <img
          :src="youtubeThumbnailUrl"
          :alt="`Thumbnail for ${postData.title}`"
          :class="
            inCarousel
              ? ''
              : 'absolute top-0 left-0 w-full h-full object-cover rounded-lg'
          "
        />
        <div class="absolute inset-0 flex items-center justify-center">
          <div class="text-red-600 text-7xl">
            <ion-icon
              :icon="playSharp"
              class="text-white text-5xl absolute inset-0 m-auto pb-2"
            />
            <ion-icon :icon="logoYoutube" />
          </div>
        </div>
      </div>
      <div
        v-if="isVimeo && !playerReady"
        class="w-full cursor-pointer select-none relative aspect-w-16 aspect-h-9"
        :class="
          inCarousel
            ? 'h-full flex justify-center'
            : 'relative aspect-w-16 aspect-h-9'
        "
        @click.stop.prevent="initPlayer"
      >
        <img
          :src="vimeoThumbnailUrl"
          :alt="`Thumbnail for ${postData.title}`"
          :class="
            inCarousel
              ? ''
              : 'absolute top-0 left-0 w-full h-full object-cover rounded-lg'
          "
        />

        <div class="absolute inset-0 flex items-center justify-center">
          <div class="text-blue-600 text-7xl">
            <ion-icon
              :icon="play"
              class="text-[#17D5FF] text-7xl absolute inset-0 m-auto"
            />
            <ion-icon
              :icon="logoVimeo"
              class="text-3xl text-[#0A0E12] absolute pr-3 pt-1 inset-0 m-auto"
            />
          </div>
        </div>
      </div>

      <!-- <div
        v-if="isRumble && loadingRumble"
        class="w-full cursor-pointer animate-pulse select-none relative aspect-w-16 aspect-h-9 bg-zinc-300 dark:bg-zinc-800"
        :class="inCarousel ? 'h-full flex justify-center' : 'relative aspect-w-16 aspect-h-9'"      
      >     
            <img :src="rumbleIcon" class="size-20 absolute pr-3 pt-1 inset-0 m-auto" /> 
      </div>
      <div
        v-else-if="isRumble && !playerReady"
        class="w-full cursor-pointer select-none relative aspect-w-16 aspect-h-9"
        :class="inCarousel ? 'h-full flex justify-center' : 'relative aspect-w-16 aspect-h-9'"
        @click.stop.prevent="initPlayer"
        
      >
      <p class="text-white  z-50 px-2 line-clamp-1 overflow-hidden h-10 rumble-title" @click.stop.prevent="nativgateToMedia">{{ rumbleTitle }}</p>
        <img
          :src="!noThumbnail && rumbleThumbnailUrl ? rumbleThumbnailUrl : rumbleIcon || rumbleIcon" 
          :class="[inCarousel ? '' : 'absolute top-0 left-0 w-full h-full object-cover rounded-lg', noThumbnail ? 'animate-pulse' : '']"
          @error="noThumbnail = true"
        />
          <div class="absolute inset-0 flex items-center justify-center">
              <img :src="rumbleIcon" class="size-20 absolute pr-3 pt-1 inset-0 m-auto" />
          </div>
      </div> -->
      <!-- Player -->
      <YoutubePlayer
        v-if="isYoutube && playerReady"
        :post-data="postData"
        @destroyed="destroyed"
        :height="height"
        :inCarousel="inCarousel"
      />
      <VimeoPlayer
        v-if="isVimeo && playerReady"
        :post-data="postData"
        @destroyed="destroyed"
        :height="height"
        :inCarousel="inCarousel"
      />
      <!-- <RumblePlayer
        v-if="isRumble && playerReady"
        :post-data="postData"
        @destroyed="destroyed"
        :height="height"
        :inCarousel="inCarousel"
        :media-url="mediaUrl"
        :rumbleEmbedUrl="rumbleEmbedUrl"
      /> -->
      <iframe
        v-if="isBitchute && postInView"
        :src="postData.embedUrl"
        width="100%"
        height="360"
        title="Bitchute Video"
        frameborder="0"
        style="border-radius: 0.5rem"
        allowfullscreen
      >
      </iframe>
      <iframe
        v-if="postData.embedUrl.startsWith('https://embed.music.apple')"
        :src="`${postData.embedUrl}`"
        title=""
        allow="autoplay *; encrypted-media *; fullscreen *"
        height="450"
        style="
          width: 100%;
          max-width: 660px;
          overflow: hidden;
          background: transparent;
        "
        sandbox="allow-forms allow-popups allow-same-origin allow-scripts allow-storage-access-by-user-activation allow-top-navigation-by-user-activation"
      ></iframe>

      <iframe
        v-if="postData.embedUrl.startsWith('https://open.spotify')"
        :src="`${postData.embedUrl}`"
        width="100%"
        height="380"
        allow="encrypted-media"
        title=""
      ></iframe>

      <!-- Soundcloud embeds are broken server side
        <iframe v-if="postData.embedUrl.startsWith('https://w.soundcloud')" :src="`${postData.embedUrl}`" width="100%"
        height="166" scrolling="no" frameborder="no" allow="autoplay" title=""></iframe> 
      -->

      <div
        v-if="postData.embedUrl.startsWith('https://www.tiktok')"
        style="
          position: relative;
          padding-bottom: 56.25%;
          height: 0;
          overflow: hidden;
        "
      >
        <iframe
          ref="TiktokEmbed"
          :src="`${postData.embedUrl}`"
          style="
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
          "
          title=""
        ></iframe>
      </div>

      <div
        v-if="isInstagram"
        class="flex flex-col border rounded-lg justify-center items-center"
      >
        <!-- Your placeholder here, e.g., an image or button indicating an Instagram post -->
        <div
          class="flex flex-col justify-between items-center m-auto cursor-pointer select-none"
          @click.stop.prevent="showInstagramEmbed"
        >
          <ion-icon
            :icon="logoInstagram"
            style="font-size: 48px; color: #c13584"
          ></ion-icon>
          <p>{{ showInstagram ? "Hide" : "" }} Instagram Embed</p>
        </div>
        <div v-if="showInstagram" class="aspect-w-9 aspect-h-16 w-full">
          <iframe
            ref="InstagramEmbed"
            :src="`${postData.embedUrl}`"
            title=""
            class="w-full"
          ></iframe>
        </div>
      </div>

      <!-- Rumble appears to have disabled embeds
        <iframe v-if="postData.embedUrl.startsWith('https://rumble.com')" ref="RumbleEmbed" :src="`${postData.embedUrl}`"
        width="640" height="360" sandbox="allow-scripts allow-same-origin" layout="responsive" frameborder="0">
      </iframe> -->
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onBeforeUnmount, onMounted } from "vue";
import { useGlobalStore } from "@/stores/global";
import { IonIcon } from "@ionic/vue";
import {
  logoInstagram,
  logoYoutube,
  play,
  playSharp,
  logoVimeo,
} from "ionicons/icons";
import YoutubePlayer from "./YoutubePlayer.vue";
import VimeoPlayer from "./VimeoPlayer.vue";
import RumblePlayer from "./RumblePlayer.vue";
import rumbleIcon from "@/assets/rumble-player.svg";
import { fetchThumbnailAndEmbedUrl } from "@/helpers/fetchThumbnail.js";

const props = defineProps({
  postData: {
    type: Object,
    required: true,
  },
  height: {
    type: Number,
    required: false,
  },
  inCarousel: {
    type: Boolean,
    required: false,
    default: false,
  },
  postInView: {
    type: Boolean,
    required: false,
    default: false,
  },
  mediaUrl: {
    type: String,
    required: false,
  },
});

const global = useGlobalStore();
const target = ref(null);
const TiktokEmbed = ref(null);
const InstagramEmbed = ref(null);
const youtubeIframe = ref(null);
const playerReady = ref(false);
const youtubePlayer = ref(null);
const showInstagram = ref(false);
const rumbleThumbnailUrl = ref(null);
const rumbleEmbedUrl = ref(null);
const rumbleTitle = ref(null);
const noThumbnail = ref(false);
const loadingRumble = ref(false);

// onMounted(async () => {
//   if (isRumble.value) {

//     loadingRumble.value = true;

//     const { thumbnail, embedUrl, title } = await fetchThumbnailAndEmbedUrl(props.mediaUrl);

//     rumbleThumbnailUrl.value = thumbnail ? thumbnail : rumbleIcon;
//     rumbleEmbedUrl.value = embedUrl ? embedUrl : props.postData.embedUrl;
//     rumbleTitle.value = title ? title : props.postData.title;

//   loadingRumble.value = false;
//   }
// });

const nativgateToMedia = () => {
  window.open(props.mediaUrl, "_blank");
};

// Method to dynamically load Instagram's embed script
const loadInstagramEmbedScript = () => {
  const script = document.createElement("script");
  script.async = true;
  script.src = "//www.instagram.com/embed.js";
  document.body.appendChild(script);

  script.onload = () => {
    window.instgrm.Embeds.process(); // This will transform all .instagram-media blocks
  };
};

// Method to remove Instagram embed script
const removeInstagramEmbedScript = () => {
  const script = document.querySelector(
    'script[src="//www.instagram.com/embed.js"]'
  );
  if (script) {
    script.remove();
  }
};

// Function triggered by clicking the Instagram placeholder
const showInstagramEmbed = () => {
  if (showInstagram.value) {
    showInstagram.value = false;
    return;
  }
  // Ensure the Instagram script is loaded and the embeds are processed
  if (!window.instgrm) {
    loadInstagramEmbedScript();
  } else {
    window.instgrm.Embeds.process();
  }

  showInstagram.value = true;
};

const isYoutube = computed(() =>
  props.postData.embedUrl.startsWith("https://www.youtube")
);
const youtubeThumbnailUrl = computed(
  () =>
    `https://img.youtube.com/vi/${props.postData.embedUrl
      .split("/")
      .pop()}/0.jpg`
);

const isBitchute = computed(() =>
  props.postData.embedUrl.startsWith("https://www.bitchute.com/")
);

const isVimeo = computed(() =>
  props.postData.embedUrl.startsWith(
    "https://player.vimeo" || "https://vimeo.com"
  )
);

const isRumble = computed(() =>
  props.postData.embedUrl.startsWith("https://rumble.com/")
);

const vimeoThumbnailUrl = computed(() => {
  const videoId = props.postData.embedUrl.split("/").pop();
  return `https://vumbnail.com/${videoId}.jpg`;
});

const isInstagram = computed(() =>
  props.postData.embedUrl.startsWith("https://www.instagram")
);

// Function to initialize the YouTube player after thumbnail click
const initPlayer = () => {
  playerReady.value = true;
};

const destroyed = () => {
  playerReady.value = false;
};

onBeforeUnmount(() => {
  if (youtubePlayer.value) {
    youtubePlayer.value.destroy();
  }
  if (TiktokEmbed.value) {
    TiktokEmbed.value.remove();
  }
  if (InstagramEmbed.value) {
    InstagramEmbed.value.remove();
  }
  if (youtubeIframe.value) {
    youtubeIframe.value.remove();
  }
  if (showInstagram.value) {
    showInstagram.value = false;
  }
  removeInstagramEmbedScript();
});
</script>
<style scoped>
.rumble-title {
  margin: 0px;
  padding: 9px;
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0));
  opacity: 1;
  font: 18px sans-serif;
  box-sizing: content-box;
  border: none;
  visibility: visible;
  text-size-adjust: auto;
  text-decoration: none;
  outline: 0px;
  text-shadow: rgba(0, 0, 0, 0.5) 0px 0px 2px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}
</style>
