<template>
  <div
    v-if="
      (lastActiveAt && mutualFollowing && profile) ||
      (onFriendsFeed && mutualFollowing)
    "
    class="last-active flex items-center rounded-full bg-white dark:bg-zinc-800 shadow-md text-sm"
    :class="profile ? 'px-1 space-x-1' : 'p-px'"
    :title="userActive ? 'Active' : `${timeAgoActive} ago`"
  >
    <p
      v-if="profile"
      :class="userActive ? 'text-green-500' : 'text-black dark:text-white'"
    >
      <span v-if="userActive">Active</span>
      <span v-else>{{ timeAgoActive }} ago</span>
    </p>
    <div
      class="rounded-full"
      :class="[
        profile ? 'size-4' : 'size-3',
        userActive ? 'bg-green-500' : 'bg-gray-500',
      ]"
    ></div>
  </div>
</template>
<script setup>
import { computed, ref, watch } from "vue";
import { timeAgo } from "@/helpers/timeAgo.js";
import { useUserStore } from "@/stores/users";
import { useGlobalStore } from "@/stores/global";
import { useAuthStore } from "@/stores/auth";
import { useRoute } from "vue-router";

const props = defineProps({
  id: {
    type: String,
    required: false,
  },
  profile: {
    type: Boolean,
    default: false,
  },
});

const router = useRoute();
const userStore = useUserStore();
const globalStore = useGlobalStore();
const authStore = useAuthStore();
const currentUser = computed(() => authStore.currentUser || null);
const isCurrentUser = computed(() => currentUser.value?.ulid === props.id);
const mutualFollowing =
  computed(
    () =>
      userStore.users[props.id]?.ProfileEngagement.isFollowing &&
      userStore.users[props.id]?.ProfileEngagement.isFollowingYou
  ) || null;

const lastActiveAt =
  computed(() => userStore.users[props.id]?.lastActiveAt) || null;

const onFriendsFeed = computed(
  () => !props.profile && globalStore.currentFeed === "followingMutual"
);

const timeAgoActive = computed(() => {
  if (!lastActiveAt.value) {
    return "A while";
  }
  return timeAgo(new Date(lastActiveAt.value));
});

const userActive = computed(() => {
  const lastActiveTimestamp = lastActiveAt.value;
  if (!lastActiveTimestamp) {
    return false;
  }
  const lastActiveDate = new Date(lastActiveTimestamp);
  const currentDate = new Date();
  const timeDifference = currentDate - lastActiveDate;
  const differenceInMinutes = timeDifference / 60000; // Convert milliseconds to minutes
  return differenceInMinutes < 10;
});
</script>

<style scoped lang="scss">
ion-content.min {
  .last-active {
    background-color: initial;
    padding: initial;
    zoom: 0.9;

    > p {
      display: none;
    }
  }
}
</style>
