<template>
    <!-- Search Icon -->
    <svg :width="width" :height="height" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M11.6997 19C16.118 19 19.6997 15.4183 19.6997 11C19.6997 6.58172 16.118 3 11.6997 3C7.28143 3 3.69971 6.58172 3.69971 11C3.69971 15.4183 7.28143 19 11.6997 19Z"
            :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
        <path d="M21.6996 20.9999L17.3496 16.6499" :stroke="color" stroke-linecap="round" stroke-linejoin="round" />
    </svg>
</template>
<script setup>
import { computed } from 'vue'
import { useGlobalStore } from '@/stores/global'

const props = defineProps({
    active: {
        type: Boolean,
        default: false
    },
    height: {
        type: Number,
        default: 24
    },
    width: {
        type: Number,
        default: 24
    }
})

const globalStore = useGlobalStore()

const darkMode = computed(() => {
    return globalStore.settings.dark_mode;
})

const color = computed(() => {
    return props.active ? 'var(--primary-color)' : (darkMode.value ? '#fff' : '#525252')
})


</script>



<style></style>