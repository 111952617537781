<template>
  <div class="flex items-center h-auto justify-center overflow-hidden">
    <div
      v-for="(digit, index) in maxDigitsLength"
      :key="index"
      class="{ 'decimal': newFormattedDigits[index] === '.' }"
    >
      <transition name="slide-up" mode="out-in">
        <div
          class="digit"
          :key="newFormattedDigits[index]"
          :style="{ '--animation-delay': `${(maxDigitsLength - 1 - index) * 0.1}s` }"
        >
          <!-- This line sets the delay -->
          {{ newFormattedDigits[index] || "0" }}
        </div>
      </transition>
    </div>
    <Transition name="slide-up" mode="out-in">
      <div
        class="digit"
        v-if="isThousand || isMillion"
        :key="isThousand ? 'K' : 'M'"
        :style="{ '--animation-delay': `${(maxDigitsLength - 1) * 0.1}s` }"
      >
        <!-- This line sets the delay -->
        {{ isThousand ? "K" : "M" }}
      </div>
    </Transition>
  </div>
</template>

<script setup>
import { computed, ref, watch, onMounted } from "vue";

const props = defineProps({
  count: Number,
});

const oldCount = ref(props.count);
const newCount = ref(props.count);
const mounted = ref(false);
const isThousand = computed(() => props.count >= 1000 && !isMillion.value);
const isMillion = computed(() => props.count >= 1000000);

function formatNumber(number) {
  if (number === undefined) {
    return "";
  }
  if (number >= 1000000) {
    return (number / 1000000).toFixed(1);
  } else if (number >= 1000) {
    return (number / 1000).toFixed(1);
  } else {
    return number.toString();
  }
}

const maxLength = (a, b) => Math.max(a.length, b.length);

const padDigits = (str, length) => {
  return str.padStart(length, " ").split("");
};

const oldFormatted = computed(() => formatNumber(oldCount.value));
const newFormatted = computed(() => formatNumber(newCount.value));

const oldFormattedDigits = computed(() =>
  padDigits(oldFormatted.value, maxLength(oldFormatted.value, newFormatted.value))
);
const newFormattedDigits = computed(() =>
  padDigits(newFormatted.value, maxLength(oldFormatted.value, newFormatted.value))
);

const maxDigitsLength = computed(() => maxLength(oldFormatted.value, newFormatted.value));

// Method to determine if the digit should be animated
function shouldAnimateDigit(index) {
  const shouldAnimate =
    mounted.value && oldFormattedDigits.value[index] !== newFormattedDigits.value[index];

  return shouldAnimate;
}

watch(
  () => props.count,
  (newValue, oldValue) => {
    if (mounted.value) {
      oldCount.value = oldValue;
    }
    newCount.value = newValue;
  }
);

onMounted(() => {
  mounted.value = true;
});
</script>

<style scoped>
.slide-up-enter-active,
.slide-up-leave-active {
  transition: transform 0.3s ease;
}

.slide-up-enter,
.slide-up-leave-to

/* .slide-up-leave-active for versions below Vue 2.1.8 */ {
  transform: translateY(100%);
  /* Start from below */
}

.slide-up-enter-active,
.slide-up-leave-active {
  /* Apply a delay based on the index of the digit, assuming `var(--animation-delay)` is set on the element */
  transition: transform 0.3s ease var(--animation-delay);
}

.slide-up-leave-active {
  transform: translateY(0);
  /* End at the final position */
}

.slide-up-leave-to {
  transform: translateY(-100%);
  /* Move up out of the container */
}
</style>
