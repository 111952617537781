<template>
  <transition name="slide-left">
    <div
      @click.stop.prevent
      v-if="post.postEngagement?.reactions && open"
      class="flex flex-wrap items-start justify-start overflow-hidden gap-x-2"
    >
      <ion-icon
        :icon="close"
        class="text-2xl cursor-pointer ht-7 mt-1"
        @click="closeDetails"
      />
      <div
        class="border rounded-full flex items-center justify-center gap-x-1 px-2 h-6 min-w-12 text-[1rem] align-middle cursor-pointer select-none mt-1"
        :class="
          reaction.name === post.userReaction
            ? 'primary-border'
            : 'border-gray-300 dark:border-gray-500'
        "
        v-for="reaction of displayedReactions"
        :key="reaction.name"
        @click="handleReaction(reaction?.name)"
      >
        <ion-icon
          v-if="reaction.name === 'heart'"
          :icon="heart"
          class="text-red-500 align-middle p-2"
        />
        <div v-else class="p-1">{{ getReactionValue(reaction.name) }}</div>
        <FormatCount :count="getReactionCount(reaction.name)" />
      </div>
      <div v-if="displayedReactions.length > 3" class="flex mt-1 items-center h-7">
        <ion-icon
          :icon="showMoreIcon"
          @click="toggleShowMore"
          class="align-middle text-2xl cursor-pointer"
        />
      </div>
    </div>
  </transition>
</template>

<script setup>
import { ref, computed, onBeforeUnmount } from "vue";
import { usePostStore } from "@/stores/post";
import { useGlobalStore } from "@/stores/global";
import { IonIcon } from "@ionic/vue";
import { close } from "ionicons/icons";
import FormatCount from "@/components/FormatCount.vue";

import { addCircleOutline, removeCircleOutline, heart } from "ionicons/icons";

const emit = defineEmits(["showToast", "closeDetails"]);
const postStore = usePostStore();
const props = defineProps({
  parentItem: Object,
  isNestedFeed: {
    type: Boolean,
    default: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
  },
  open: {
    type: Boolean,
    default: false,
  },
});

const toastOpen = ref(false);

const closeDetails = () => {
  emit("closeDetails");
};

const globalStore = useGlobalStore();

const post = computed(() => {
  return postStore.postsCache[props.parentItem?.id] || {};
});

const reactionData = computed(() => {
  const postReactions = globalStore.reactionTypes;
  const sortedReactions = postReactions
    .map((reaction) => {
      const postReaction = post.value?.postEngagement?.reactions.find(
        (r) => r.name === reaction.name
      );
      return {
        name: reaction.name,
        value: reaction.value || "",
        count: postReaction?.count || 0,
        category: reaction.category, // Include the category
      };
    })
    .filter((reaction) => reaction.count > 0)
    .sort((a, b) => {
      if (b.count === a.count) {
        const categoryOrder = {
          positive: 1,
          neutral: 2,
          negative: 3,
        };
        return categoryOrder[a.category] - categoryOrder[b.category];
      }
      return b.count - a.count;
    });

  return sortedReactions;
});

const loading = ref(false);
const popoverReactionsOpen = ref(false);

const showAllReactions = ref(false);

const displayedReactions = computed(() => {
  return showAllReactions.value ? reactionData.value : reactionData.value.slice(0, 3);
});

const toggleShowMore = () => {
  showAllReactions.value = !showAllReactions.value;
};

const showMoreIcon = computed(() => {
  return showAllReactions.value ? removeCircleOutline : addCircleOutline;
});

const getReactionValue = (reactionName) => {
  const reaction = reactionData.value.find((r) => r.name === reactionName);
  return reaction ? reaction.value : "";
};

let getReactionCount = (reactionType) => {
  const reactions = post.value?.postEngagement?.reactions || [];

  let count = 0;

  if (Array.isArray(reactions)) {
    const reactionEntry = reactions.find((r) => r && r.name === reactionType);

    if (reactionEntry?.count && typeof reactionEntry.count === "number") {
      count = reactionEntry.count;
    }
  }

  return count;
};

async function handleReaction(reactionType) {
  if (loading.value) {
    return; // Do nothing if the function is already running
  }

  loading.value = true; // Set loading state to true

  toastOpen.value = false;
  try {
    let currentReaction = postStore.postsCache[props.parentItem.id].userReaction;

    if (currentReaction) {
      // Check if the selected reaction is the same as the user's current reaction
      if (reactionType === currentReaction) {
        // Update the postStore's reactions
        const response = await postStore.updatePostReaction(
          post.value.id,
          reactionType,
          "delete"
        );

        if (response.success) {
          // Display the toast message for removing the reaction
          showToast("Your reaction has been removed.", "success");

          updateReaction(reactionType, "delete");
        } else {
          // Display the toast message for the delete error
          showToast("Error removing the reaction.", "danger");
        }
      } else {
        // Update the postStore's reactions for the previous reaction
        const currentReactionResponse = await postStore.updatePostReaction(
          post.value.id,
          currentReaction,
          "delete"
        );

        if (!currentReactionResponse.success) {
          // Display the toast message for the previous reaction delete error
          showToast("Error removing your previous reaction.", "danger");
        } else {
          updateReaction(currentReaction, "delete");
        }

        // Update the postStore's reactions for the new reaction

        const newReactionResponse = await postStore.updatePostReaction(
          post.value.id,
          reactionType,
          "add"
        );

        if (newReactionResponse.success) {
          // Display the toast message for successful reaction
          showToast("Your reaction has been changed.", "success");

          updateReaction(reactionType, "add");
        } else {
          // Display the toast message for the create error
          showToast("Error changing the reaction.", "danger");
        }
      }
    } else {
      // Update the postStore's reactions for the new reaction
      const response = await postStore.updatePostReaction(
        post.value?.id,
        reactionType,
        "add"
      );

      if (response.success) {
        // Display the toast message for successful reaction
        showToast("Your reaction has been added.", "success");

        updateReaction(reactionType, "add");
      } else {
        // Display the toast message for the create error
        showToast("Error recording the reaction.", "danger");
      }
    }
  } catch (error) {
    // Handle any unexpected errors
    console.error(error, "ERROR)");
    showToast("An unexpected error occurred.", "danger");
  } finally {
    loading.value = false; // Reset loading state to false
    popoverReactionsOpen.value = false; // Close the popover
  }
}
function updateReaction(reactionType, action) {
  // check if there reaction is already in the reactions array
  const reactions = postStore.postsCache[props.parentItem.id].postEngagement.reactions;

  const reactionIndex = reactions.findIndex((r) => r.name === reactionType);

  if (reactionIndex !== -1) {
    // if the reaction is found
    if (action === "add") {
      reactions[reactionIndex].count++;
      postStore.postsCache[post.value.id].userReaction = reactionType;
    } else if (action === "delete") {
      if (reactions[reactionIndex].count === 1) {
        // if the count is at 1 and the action is delete, remove the reaction from the array
        reactions.splice(reactionIndex, 1);
      } else {
        reactions[reactionIndex].count--;
      }
      postStore.postsCache[post.value.id].userReaction = null;
    }
  } else {
    if (action === "delete") {
      return;
    }
    // if the reaction is not found, add it to the reactions array
    postStore.postsCache[props.parentItem.id].postEngagement.reactions.push({
      count: 1,
      name: reactionType,
    });

    postStore.postsCache[post.value.id].userReaction = reactionType;
  }
}

// Function to display the toast
function showToast(message, color) {
  emit("showToast", { message, color });
}

onBeforeUnmount(() => {
  if (popoverReactionsOpen.value) {
    popoverReactionsOpen.value = false;
  }
  if (loading.value) {
    loading.value = false;
  }
  if (toastOpen.value) {
    toastOpen.value = false;
  }
});
</script>

<style scoped>
ion-popover {
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --width: 220px;
  --min-height: 40px;
}

.slide-left-enter-active,
.slide-left-leave-active {
  transition: transform 0.3s;
}

.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-100%);
}

ion-popover::part(content) {
  border-radius: 2rem;
}

.custom-border {
  border: 1px solid !important;
}

.h-inherit {
  height: inherit;
}

.primary-border {
  border-color: var(--primary-color);
}
</style>
