<template>
    <!-- Home Icon -->
    <div class="pb-1">
        <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M18.4153 7.72844L10.9153 0.652188C10.9117 0.648989 10.9082 0.645543 10.905 0.641875C10.6289 0.39075 10.2691 0.251595 9.89581 0.251595C9.52256 0.251595 9.16272 0.39075 8.88659 0.641875L8.87627 0.652188L1.38471 7.72844C1.2319 7.86896 1.10992 8.03967 1.02649 8.22977C0.943055 8.41986 0.899993 8.62521 0.900024 8.83281V17.5C0.900024 17.8978 1.05806 18.2794 1.33936 18.5607C1.62067 18.842 2.0022 19 2.40002 19H6.90002C7.29785 19 7.67938 18.842 7.96068 18.5607C8.24199 18.2794 8.40002 17.8978 8.40002 17.5V13H11.4V17.5C11.4 17.8978 11.5581 18.2794 11.8394 18.5607C12.1207 18.842 12.5022 19 12.9 19H17.4C17.7978 19 18.1794 18.842 18.4607 18.5607C18.742 18.2794 18.9 17.8978 18.9 17.5V8.83281C18.9001 8.62521 18.857 8.41986 18.7736 8.22977C18.6901 8.03967 18.5682 7.86896 18.4153 7.72844ZM17.4 17.5H12.9V13C12.9 12.6022 12.742 12.2206 12.4607 11.9393C12.1794 11.658 11.7978 11.5 11.4 11.5H8.40002C8.0022 11.5 7.62067 11.658 7.33936 11.9393C7.05806 12.2206 6.90002 12.6022 6.90002 13V17.5H2.40002V8.83281L2.41034 8.82344L9.90002 1.75L17.3906 8.82156L17.401 8.83094L17.4 17.5Z"
                :fill="color" />
        </svg>
    </div>
</template>
<script setup>
import { computed } from 'vue'
import { useGlobalStore } from '@/stores/global'

const props = defineProps({
    active: {
        type: Boolean,
        default: false
    }
})

const globalStore = useGlobalStore()

const darkMode = computed(() => {
    return globalStore.settings.dark_mode;
})

const color = computed(() => {
    return props.active ? 'var(--primary-color)' : (darkMode.value ? '#fff' : '#525252')
})


</script>



<style></style>