<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.25 4.125H2.75C2.56766 4.125 2.3928 4.19743 2.26386 4.32636C2.13493 4.4553 2.0625 4.63016 2.0625 4.8125V16.5C2.0625 16.8647 2.20737 17.2144 2.46523 17.4723C2.72309 17.7301 3.07283 17.875 3.4375 17.875H18.5625C18.9272 17.875 19.2769 17.7301 19.5348 17.4723C19.7926 17.2144 19.9375 16.8647 19.9375 16.5V4.8125C19.9375 4.63016 19.8651 4.4553 19.7361 4.32636C19.6072 4.19743 19.4323 4.125 19.25 4.125ZM17.4823 5.5L11 11.4426L4.51773 5.5H17.4823ZM18.5625 16.5H3.4375V6.3757L10.5351 12.882C10.6619 12.9985 10.8278 13.0631 11 13.0631C11.1722 13.0631 11.3381 12.9985 11.4649 12.882L18.5625 6.3757V16.5Z"
      :fill="color"
    />
  </svg>
</template>
<script setup>
import { computed } from "vue";
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const globalStore = useGlobalStore();

const darkMode = computed(() => {
  return globalStore.settings.dark_mode;
});

const color = computed(() => {
  return darkMode.value ? "#fff" : "#000";
});
</script>
