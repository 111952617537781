export const timeAgo = (date) => {
    const seconds = Math.floor((new Date() - date) / 1000);

    let interval = Math.floor(seconds / 60);
    if (interval < 1) {
        return Math.floor(seconds) + "s";
    }

    interval = Math.floor(seconds / 3600);
    if (interval < 1) {
        return Math.floor(seconds / 60) + "m";
    }

    if (interval < 24) {
        return interval + "h";
    }

    // // When time is more than 72 hours, return date
    // if (interval >= 72) {
    //     const day = date.getDate();
    //     const month = date.getMonth() + 1; // Months are zero based
    //     const year = date.getFullYear();

    //     return `${month}/${day}/${year}`; // adjust date format as needed
    // }

    let days = Math.floor(interval / 24);

    if (days < 2) {
        return days + "d";
    }

    return days + "d";
};

export const commentTimeAgo = (date) => {
    const now = new Date();
    const seconds = Math.floor((now - date) / 1000);

    let interval = Math.floor(seconds / 60);
    if (interval < 1) {
        return Math.floor(seconds) + "s"; // Short for seconds
    }

    interval = Math.floor(seconds / 3600);
    if (interval < 1) {
        return Math.floor(seconds / 60) + "m"; // Short for minutes
    }

    if (interval < 24) {
        return interval + "h"; // Short for hours
    }

    // When time is more than 72 hours, return date
    // if (interval >= 72) {
    //     const day = date.getDate();
    //     const month = date.getMonth() + 1; // Months are zero based
    //     const year = date.getFullYear();
    //     const twoDigitYear = year.toString().substr(-2);
    //     const currentYear = now.getFullYear();

    //     // Only show the year if it's different from the current year
    //     return `${month}/${day}` + (year !== currentYear ? `/${twoDigitYear}` : '');
    // }

    return Math.floor(interval / 24) + "d"; // Short for days
};