<template>
  <ion-modal
    @didDismiss="closeTimeoutAlert"
    :is-open="postStore.timeoutModalOpen"
    css-class="timeout-modal"
  >
    <ion-content scroll-y="false">
      <div
        class="p-8 flex flex-col justify-center items-center gap-y-4 h-full text-center"
      >
        <ion-icon
          :icon="warningOutline"
          class="text-4xl text-[#E50038] flex-shrink-0"
        />
        <h1 class="text-2xl font-bold">Account Timeout</h1>
        <div class="flex items-center justify-center gap-x-2">
          <ion-icon
            :src="timeoutIcon"
            class="text-2xl text-[#343330] dark:text-white"
          />
          <h2 class="remaining-time">{{ remainingTime }}</h2>
        </div>
        <p class="message">{{ timeoutMessage }}</p>
        <button
          class="w-full mt-4 p-2 rounded-full border border-[#D4D4D4]"
          @click="closeTimeoutAlert"
        >
          Cancel
        </button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import { ref, watch } from "vue";
import { usePostStore } from "@/stores/post";
import { IonModal, IonContent, IonIcon } from "@ionic/vue";
import { warningOutline } from "ionicons/icons";
import timeoutIcon from "@/assets/parler/timeout.svg";

const postStore = usePostStore();
const timeoutMessage = ref("");
const remainingTime = ref("");
let countdownInterval = null;

const closeTimeoutAlert = () => {
  postStore.timeoutModalOpen = false;
  clearInterval(countdownInterval);
};

watch(
  () => postStore.timeoutModalOpen,
  (isOpen) => {
    if (isOpen) {
      startCountdown(postStore.timeoutUntil);
    }
  }
);

// Function to start the countdown timer
function startCountdown(timeoutUntil) {
  countdownInterval = setInterval(() => {
    const now = new Date();
    const timeDifference = new Date(timeoutUntil) - now;

    let actionMessage = "";
    switch (postStore.timeoutAction) {
      case "post":
        actionMessage = "posting";
        break;
      case "comment":
        actionMessage = "commenting";
        break;
      case "quote":
        actionMessage = "quoting";
        break;
      case "repost":
        actionMessage = "reposting";
        break;
      default:
        actionMessage = "performing this action";
    }

    if (timeDifference <= 0) {
      clearInterval(countdownInterval);
      remainingTime.value = "0s";
      timeoutMessage.value = "You can now proceed with the action.";
    } else {
      const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      const hours = Math.floor((timeDifference / (1000 * 60 * 60)) % 24);
      const minutes = Math.floor((timeDifference / (1000 * 60)) % 60);
      const seconds = Math.floor((timeDifference / 1000) % 60);

      let message = `You’re temporarily restricted from ${actionMessage} for `;

      // Reset `remainingTime.value` at each tick to avoid accumulation
      remainingTime.value = "";

      if (days > 0) remainingTime.value += `${days}d `;
      if (hours > 0) remainingTime.value += `${hours}h `;
      if (minutes > 0) remainingTime.value += `${minutes}m `;
      if (seconds > 0) remainingTime.value += `${seconds}s`;

      timeoutMessage.value = `${message}${remainingTime.value.trim()}. You can still interact by reacting to posts and comments.`;
    }
  }, 1000);
}
</script>

<style scoped>
.timeout-modal .modal-content {
  padding: 20px;
  text-align: center;
}

.remaining-time {
  color: #e50038;
  font-size: 24px;
}

button {
  border: 1px solid #d4d4d4 !important;
}

ion-modal {
  --height: 350px;
  --width: 400px;
  --max-width: 90vw;
  --border-radius: 20px;
}
</style>
