<template>
  <span class="url-container">
    <!-- Truncated URL Display -->
    <span
      class="line-clamp-1 truncated-url cursor-pointer hover:underline"
      :title="fullUrl"
      @click.stop.prevent="navigateToUrl(fullUrl)"
    >
      {{ fullUrl }}
    </span>
  </span>
</template>

<script setup>
const props = defineProps({
  fullUrl: String,
  width: Number,
});

const navigateToUrl = (url) => {
  window.open(url, "_blank", "noopener,noreferrer");
};
</script>

<style scoped>
/* Ensure the URL is displayed in a single line and truncated with ellipsis */
.truncated-url {
  color: var(--tertiary-color); /* Adjust the color as needed */
}

.url-container {
  width: 100%; /* Adjust the width based on your card layout */
}
</style>
