// analyticsHelper.js

import { event as gtagEvent } from 'vue-gtag'; // Correctly import event


function createCustomEventName(method, url) {
  const pathSegments = url.split('/');
  const simplifiedPath = pathSegments[pathSegments.length - 1].split('?')[0]; // Exclude query parameters
  return `${method.toUpperCase()} ${simplifiedPath}`.trim();
}

export const sendToAnalytics = ({ method, url }) => {
  // Construct the event name using the new naming convention
  const eventName = createCustomEventName(method, url);

  // Send to Google Analytics
  gtagEvent(eventName, { event_category: 'API', method, path: url });
};

export const sendErrorToAnalytics = ({ method, url, error }) => {
  // Format error details similarly to successful requests for consistency
  const eventName = "API Error"; // Consider using a more descriptive event name if applicable
  const errorDetails = {
    method,
    url,
    status: error.response?.status,
    statusText: error.response?.statusText,
  };

  // Send a simplified version to Google Analytics (GA4)
  gtagEvent('event', eventName, {
    event_category: 'API Error',
    event_label: `Error: ${errorDetails.status} ${errorDetails.statusText}`,
    // Do not include personal data
  });
};
