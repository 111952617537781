<template>
  <div
    ref="playerContainer"
    :class="'aspect-w-16 aspect-h-9'"
    :style="{ height: height ? `${height}px` : '' }"
  >
    <iframe
      :id="postData.id + '-embed'"
      :src="`${postData.embedUrl}?enablejsapi=1`"
      width="100%"
      height="100%"
      style="border-radius: 0.5rem"
      title="YouTube Video Player"
      allow=" autoplay; accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowfullscreen
    ></iframe>
  </div>
</template>

<script setup>

import { ref, onMounted, onBeforeUnmount, watch } from "vue";
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  postData: {
    type: Object,
    required: true,
  },
  height: {
    type: Number,
    default: 0,
  },
  inCarousel: {
    type: Boolean,
    default: false,
  },
});

const global = useGlobalStore();
const target = ref(null);
const playerContainer = ref(null);
const youtubePlayer = ref(null);
const youtubeIframe = ref(null);

const emit = defineEmits(["destroyed"]);

let observer;

let containerObserver;

const onVisibilityChange = (entries) => {
  entries.forEach((entry) => {
    if (!entry.isIntersecting && youtubePlayer.value) {
      emit("destroyed");
      destroyYoutubePlayer();
    }
  });
};

onMounted(() => {
  // Create an intersection observer instance
  containerObserver = new IntersectionObserver(onVisibilityChange, {
    root: null, // relative to the viewport
    rootMargin: "0px",
    threshold: 0.1, // 10% of the item's visible area is enough to consider it as "not in view"
  });

  // Observe the target element
  if (playerContainer.value) {
    containerObserver.observe(playerContainer.value);
  }

  if (props.postData.embedUrl.startsWith("https://www.youtube")) {
    if (window.YT && YT.Player) {
      youtubePlayer.value = new YT.Player(props.postData.id + "-embed", {
        events: {
          onReady: onPlayerReady,
          onStateChange: onPlayerStateChange,
        },
      });

      if (youtubePlayer.value) {
        youtubeIframe.value = youtubePlayer.value.getIframe().id;
      }
    } else {
      // If YT is not ready yet, then set up the callback
      window.onYouTubeIframeAPIReady = () => {
        youtubePlayer.value = new YT.Player(props.postData.id + "-embed", {
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
      };
    }
  }
});

onBeforeUnmount(() => {
  // Disconnect the observer
  if (containerObserver && playerContainer.value) {
    containerObserver.unobserve(playerContainer.value);
  }

  // Destroy the YouTube player
  destroyYoutubePlayer();

  // Remove the callback
  window.onYouTubeIframeAPIReady = null;
});

watch(
  () => global.playingVideoId,
  (newVal) => {
    if (newVal !== youtubeIframe.value) {
      youtubePlayer.value.pauseVideo();
    }
  }
);

watch(
  () => props.postData,
  (newVal, oldVal) => {
    // Assuming the video should be stopped when new post data is received
    if (oldVal && newVal.id !== oldVal.id && youtubePlayer.value) {
      youtubePlayer.value.pauseVideo();
    }
  },
  { deep: true }
);

function onPlayerReady(event) {
  youtubeIframe.value = youtubePlayer.value.getIframe().id;

  event.target.playVideo();
}

function onPlayerStateChange(event) {
  const iframeId = event.target.getIframe().id;

  if (iframeId === youtubeIframe.value && event.data === YT.PlayerState.PLAYING) {
    global.playingVideoId = iframeId;
  }
}

const destroyYoutubePlayer = () => {
  if (youtubePlayer.value) {
    youtubePlayer.value.destroy();
    youtubePlayer.value = null;
  }
};
</script>
<style scoped>
.player {
  border: none;
  border-radius: 0.5rem !important;
}
</style>
