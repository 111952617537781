import { defineStore } from "pinia";
import { useUserStore } from "./users";
import { usePostStore } from "./post";
import { useGlobalStore } from "./global";
import { apiBackendAuthAxios } from "@/axiosAuth";

export const useNotificationStore = defineStore({
  id: "notificationStore",
  state: () => ({
    // Store properties
    notifications: {
      all: [],
      follow: [],
      follow_request: [],
      follow_acccepted: [],
      reactions: [],
      repost: [],
      repost_with_comment: [],
      comment: [],
      mention: [],
      unseen: [],
    },
    nextCursors: {
      all: null,
      follow: null,
      follow_request: null,
      follow_acccepted: null,
      reactions: null,
      repost: null,
      repost_with_comment: null,
      comment: null,
      mention: null,
    },

    totalUnseen: 0,
  }),
  actions: {
    // Fetches user notifications, with optional type filter
    async fetchNotifications(type) {
      if (this.nextCursors[type] === "END") {
        return;
      }
      try {
        let endpoint = "/user/notifications";
        const params = {};

        if (type !== "all") {
          params.type = type;
        }

        if (this.nextCursors[type] !== null) {
          params.cursor = this.nextCursors[type];
        }

        const response = await apiBackendAuthAxios.get(endpoint, { params });

        const notificationsData = response.data.data;

        // Check for new notification type and refetch type from global store is so
        const globalStore = useGlobalStore();
        // collect types from notifications
        const notificationTypes = [
          ...new Set(notificationsData.map((item) => item.notificationType)),
        ];

        // check if there are new types
        const newTypes = notificationTypes.filter(
          (type) => !globalStore.notificationTypes.includes(type)
        );
        if (newTypes.length > 0) {
          await globalStore.getNotificationTypes();
        }

        await this.checkUsers(notificationsData);

        this.getPosts(notificationsData);

        if (this.nextCursors[type] === null) {
          this.notifications[type] = notificationsData;
        } else {
          // Append the new notifications to the existing ones
          this.notifications[type] =
            this.notifications[type].concat(notificationsData);
        }

        if (response.data.next_cursor === null) {
          this.nextCursors[type] = "END";
          this.notifications[type].push({
            notificationId: "END",
            notificationType: "END",
          });
        } else {
          this.nextCursors[type] = response.data.next_cursor;
        }

        this.totalUnseen = 0;

        return { notifications: notificationsData };
      } catch (error) {
        console.error(error);
      }
    },

    async fetchNewNotifications(type) {
      const lastNotificationId = this.notifications[type][0].notificationId;

      try {
        const params = {};

        if (type !== "all") {
          params.type = type;
        }

        let endpoint = "/user/notifications";

        const response = await apiBackendAuthAxios.post(endpoint, {
          ulid: lastNotificationId,
        });

        if (response.data.data.length > 0) {
          const notificationsData = response.data.data;
          await this.checkUsers(notificationsData);

          this.getPosts(notificationsData);

          this.notifications[type] = notificationsData.concat(
            this.notifications[type]
          );

          this.totalUnseen = 0;

          return { notifications: notificationsData };
        }
      } catch (error) {
        console.error(
          `Error fetching new new notifications ${lastNotificationId}:`,
          error
        );
      }
    },

    clearNotifications() {
      this.notifications = {
        all: [],
        follow: [],
        follow_request: [],
        follow_acccepted: [],
        reactions: [],
        repost: [],
        repost_with_comment: [],
        comment: [],
        mention: [],
        unseen: [],
      };
      this.nextCursors = {
        all: null,
        follow: null,
        follow_request: null,
        follow_acccepted: null,
        reactions: null,
        repost: null,
        repost_with_comment: null,
        comment: null,
        mention: null,
      };
    },

    async checkUsers(notifications) {
      const userStore = useUserStore();

      const userIds = [
        ...new Set(notifications.map((item) => item.fromUserId)),
      ];
      await userStore.fetchUsersByIds(userIds);
    },

    async refreshNotifications(type) {
      this.nextCursors[type] = null;
      this.notifications[type] = [];
      await this.fetchNotifications(type);
    },

    async checkNotifications() {
      try {
        let endpoint = "/user/notifications/count";

        const response = await apiBackendAuthAxios.get(endpoint);
        const notificationsData = response.data;
        const seen = notificationsData.seen_count;
        const total = notificationsData.total_count;
        this.totalUnseen = total - seen;
        return { seen, total };
      } catch (error) {
        console.error(error);
      }
    },

    // Method for marking notifications as read
    async markAsRead(notificationIds) {
      try {
        const payload = { notificationIds };
        const response = await apiBackendAuthAxios.post(
          "/user/notifications/markRead",
          payload
        );

        // If the request is successful, update the notifications in the store
        if (response.status === 200) {
          // Iterate through each category of notifications
          for (const category in this.notifications) {
            if (this.notifications.hasOwnProperty(category)) {
              // Update the `read` property of each notification in the category
              this.notifications[category] = this.notifications[category].map(
                (notif) => {
                  if (notificationIds.includes(notif.notificationId)) {
                    // If the notification ID is in the list, mark it as read
                    return {
                      ...notif,
                      read: true,
                    };
                  }
                  // Otherwise, return the notification as-is
                  return notif;
                }
              );
            }
          }
        }
      } catch (error) {
        console.error("Failed to mark notifications as read: ", error);
      }
    },

    async deleteNotification(notificationIds) {
      try {
        const payload = { notificationIds: notificationIds }; // Ensuring it's an array
        const response = await apiBackendAuthAxios.delete(
          "/user/notifications/delete",
          { data: payload }
        );

        if (response.status === 200) {
          // Iterate through each category and remove the notification with the matching ID
          Object.keys(this.notifications).forEach((category) => {
            this.notifications[category] = this.notifications[category].filter(
              (notif) => !notificationIds.includes(notif.notificationId)
            );
          });
        }
      } catch (error) {
        console.error("Failed to delete notification: ", error);
      }
    },

    async markAllAsRead(lastNotificationId) {
      try {
        const payload = { lastNotificationId };
        const response = await apiBackendAuthAxios.post(
          "/user/notifications/markAllRead",
          payload
        );

        if (response.status === 200) {
          // Iterate through each category and mark all notifications as read
          Object.keys(this.notifications).forEach((category) => {
            // Check if this.notifications[category] exists and is an array before mapping
            if (Array.isArray(this.notifications[category])) {
              this.notifications[category] = this.notifications[category].map(
                (notif) => ({
                  ...notif,
                  read: true,
                })
              );
            }
          });
        }
      } catch (error) {
        console.error("Failed to mark all notifications as read: ", error);
      }
    },

    // Method for deleting all notifications
    async deleteAllNotifications(lastNotificationId) {
      try {
        const payload = { lastNotificationId };
        const response = await apiBackendAuthAxios.post(
          "/user/notifications/deleteAll",
          payload
        );

        if (response.status === 200) {
          // Iterate through each category and remove all notifications
          Object.keys(this.notifications).forEach((category) => {
            this.notifications[category] = [];
          });
        }
      } catch (error) {
        console.error("Failed to delete all notifications: ", error);
      }
    },

    // Fetches all notification types
    async getNotificationTypes() {
      try {
        const response = await apiBackendAuthAxios.get(
          "/user/notifications/types"
        );
        const notificationTypesData = response.data.data;
        this.notificationTypes = notificationTypesData;
        return notificationTypesData;
      } catch (error) {
        console.error(error);
      }
    },

    async getPosts(response) {
      const postStore = usePostStore();
      const uniqueIds = [];

      // Gather unique postId and parentId values
      response.forEach((notification) => {
        if (notification.postId && !uniqueIds.includes(notification.postId)) {
          uniqueIds.push(notification.postId);
        }
        if (
          notification.parentId &&
          !uniqueIds.includes(notification.parentId)
        ) {
          uniqueIds.push(notification.parentId);
        }
      });

      // Remove posts that are already in the cache
      uniqueIds.forEach((id) => {
        if (id in postStore.postsCache) {
          const index = uniqueIds.indexOf(id);
          if (index > -1) {
            uniqueIds.splice(index, 1);
          }
        }
      });

      // Fetch posts by uniqueIds
      await postStore.fetchPostsByIds(uniqueIds);
    },
  },
});
