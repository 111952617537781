<template>
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 6.18736C11.1823 6.18736 11.3572 6.25979 11.4861 6.38872C11.615 6.51765 11.6875 6.69252 11.6875 6.87486V11.6874C11.6875 11.8697 11.615 12.0446 11.4861 12.1735C11.3572 12.3024 11.1823 12.3749 11 12.3749C10.8176 12.3749 10.6427 12.3024 10.5138 12.1735C10.3849 12.0446 10.3125 11.8697 10.3125 11.6874V6.87486C10.3125 6.69252 10.3849 6.51765 10.5138 6.38872C10.6427 6.25979 10.8176 6.18736 11 6.18736ZM9.9687 14.7811C9.9687 14.9851 10.0292 15.1845 10.1425 15.354C10.2558 15.5236 10.4169 15.6558 10.6053 15.7339C10.7937 15.8119 11.0011 15.8323 11.2011 15.7925C11.4012 15.7528 11.5849 15.6545 11.7292 15.5103C11.8734 15.3661 11.9716 15.1823 12.0114 14.9823C12.0512 14.7823 12.0308 14.5749 11.9527 14.3865C11.8747 14.198 11.7425 14.037 11.5729 13.9237C11.4033 13.8103 11.2039 13.7499 11 13.7499C10.7264 13.7499 10.4641 13.8585 10.2707 14.0519C10.0774 14.2453 9.9687 14.5076 9.9687 14.7811ZM20.625 10.9999C20.6255 11.1799 20.5903 11.3583 20.5214 11.5247C20.4525 11.6911 20.3513 11.8422 20.2236 11.9692L11.9693 20.2244C11.7117 20.4805 11.3632 20.6242 11 20.6242C10.6367 20.6242 10.2882 20.4805 10.0306 20.2244L1.78058 11.9692C1.52448 11.7116 1.38074 11.3631 1.38074 10.9999C1.38074 10.6366 1.52448 10.2881 1.78058 10.0305L10.0349 1.77533C10.2925 1.51923 10.641 1.37549 11.0043 1.37549C11.3675 1.37549 11.716 1.51923 11.9736 1.77533L20.2279 10.0305C20.3548 10.1579 20.4552 10.3091 20.5234 10.4755C20.5915 10.6419 20.626 10.8201 20.625 10.9999ZM19.25 10.9999L11 2.74986L2.74995 10.9999L11 19.2499L19.25 10.9999Z"
      :fill="color"
    />
  </svg>
</template>
<script setup>
import { computed } from "vue";
import { useGlobalStore } from "@/stores/global";

const props = defineProps({
  active: {
    type: Boolean,
    default: false,
  },
});

const globalStore = useGlobalStore();

const darkMode = computed(() => {
  return globalStore.settings.dark_mode;
});

const color = computed(() => {
  return props.active ? "var(--primary-color)" : darkMode.value ? "#fff" : "#000";
});
</script>

<style></style>
