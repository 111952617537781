<template>
  <div
    class="badges logo-wrapper flex flex-row items-center cursor-pointer pl-1 pr-1"
  >
    <div v-if="!isBanned" v-for="badge of parlerBadges" :key="badge">
      <div
        v-if="badge"
        :id="['badges' + username]"
        :class="{ 'w-3 h-3': isCommentDetails, 'rotate-svg': isMarc }"
        :title="capitalize(badge)"
        class="w-5 h-5 -mr-1 drop-shadow-sm cursor-pointer select-none"
        @click.stop.prevent="openBadgePopover($event)"
      >
        <div class="flex items-center justify-center h-full">
          <img
            :src="parlerBadge(badge)"
            :class="{ 'text-xl': isCommentDetails }"
          />
        </div>
      </div>
    </div>
    <div v-if="isCurrentUserEmployee && showAdmin" class="pl-2">
      <button
        @click.stop.prevent="openManageBadgePopover($event)"
        :id="['manage' + username]"
      >
        <ion-icon
          class="text-2xl align-middle cursor-pointer select-none"
          :icon="cogSharp"
        />
      </button>
    </div>
  </div>

  <!-- Additional popover for managing badges -->
  <ion-popover
    :is-open="showManageBadgePopover"
    :event="manageBadgeEvent"
    @didDismiss="closeManageBadgePopover"
    alignment="center"
  >
    <ion-list nested lines="none" class="cursor-pointer select-none">
      <ion-item v-for="badge of uniqueBadgesData" :key="badge.name">
        <ion-label>{{
          badge.name === "infuencer" ? "influencer" : badge.name
        }}</ion-label>
        <ion-button @click="addOrRemoveBadge(badge.name)">
          {{ badges && badges.includes(badge.name) ? "Remove" : "Add" }}
        </ion-button>
      </ion-item>
      <ion-item
        v-if="
          isCurrentUserEmployee &&
          isCurrentUserModerator &&
          authStore.currentUser.ulid !== userId &&
          !isEmployee &&
          !areYouSure
        "
      >
        <ion-button @click="comfirm"
          ><ion-icon
            :icon="moderationIcon"
            class="align-middle pr-1 cursor-pointer select-none"
          />
          <ion-label>{{
            isBanned ? "Unban User" : "Ban User"
          }}</ion-label></ion-button
        >
      </ion-item>
      <ion-item
        v-if="
          isCurrentUserEmployee &&
          isCurrentUserModerator &&
          authStore.currentUser.ulid !== userId &&
          !isEmployee &&
          areYouSure
        "
      >
        <div class="border-2 border-red-500 rounded-lg p-2 w-full">
          <p class="text-sm pb-2">
            Are you sure you want to {{ isBanned ? "unban" : "ban" }} this user?
          </p>
          <ion-button @click="adminBanAndUnban"
            ><ion-icon
              :icon="moderationIcon"
              class="align-middle cursor-pointer select-none"
            />
            <ion-label>{{
              isBanned ? "Unban User" : "Ban User"
            }}</ion-label></ion-button
          >
        </div>
      </ion-item>
    </ion-list>
  </ion-popover>

  <!-- Ionic Popover -->
  <ion-popover
    :is-open="showBadgePopover"
    :keep-contents-mounted="true"
    :event="popoverEvent"
    @didDismiss="showBadgePopover = false"
    alignment="center"
  >
    <ion-list nested lines="none" class="firstPop grid-cols-2">
      <div
        :id="[badge + username]"
        v-for="badge of parlerBadges"
        :key="badge"
        class="flex flex-col items-center justify-center m-2 cursor-pointer select-none"
        @click.stop.prevent="openBadgeDesc($event, badge)"
      >
        <div
          :title="capitalize(badge)"
          class="badge w-24 h-24 items-center justify-center drop-shadow-md"
        >
          <div class="flex items-center justify-center text-6xl mt-4">
            <img :src="parlerBadge(badge)" />
          </div>
        </div>
        <div class="text-black dark:text-white">{{ capitalize(badge) }}</div>
      </div>
    </ion-list>
  </ion-popover>

  <ion-popover
    :is-open="showBadgeDesc"
    :keep-contents-mounted="true"
    :event="descEvent"
    @didDismiss="showBadgeDesc = false"
    :dismiss-on-select="true"
    side="end"
  >
    <div class="text-black dark:text-white popover-content">
      {{ badgeDesc(selectedBadge) }}
    </div>
  </ion-popover>
</template>

<script setup>
import { useUserStore } from "@/stores/users";
import { useAuthStore } from "@/stores/auth";
import {
  IonIcon,
  IonPopover,
  IonList,
  IonItem,
  IonLabel,
  IonButton,
} from "@ionic/vue";
import { cogSharp } from "ionicons/icons";
import moderationIcon from "@/assets/moderation_icon.svg";
import { ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useGlobalStore } from "@/stores/global";
import { useAdminStore } from "@/stores/admin";
import { usePostStore } from "@/stores/post";
import moderator from "@/assets/parler/badges/moderator.svg";
import early_adopter from "@/assets/parler/badges/early_adopter.svg";
import influencer from "@/assets/parler/badges/influencer.svg";
import verified_user from "@/assets/parler/badges/verified_user.svg";
import new_user from "@/assets/parler/badges/new_user.svg";
import verified_business from "@/assets/parler/badges/verified_business.svg";
import parody_badge from "@/assets/parler/badges/parody_badge.svg";

const props = defineProps({
  userId: {
    type: String,
    required: false,
  },
  username: {
    type: String,
    required: false,
  },
  showAdmin: {
    type: Boolean,
    default: false,
    required: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
    required: false,
  },
  isBanned: {
    type: Boolean,
    default: false,
    required: false,
  },
});

const userStore = useUserStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const postStore = usePostStore();
const adminStore = useAdminStore();
const areYouSure = ref(false);

const comfirm = () => {
  areYouSure.value = true;
};

const badges = computed(() => {
  return props.userId ? userStore.getBadges(props.userId) : "";
});

// Only return Early Adopter, verified, and influencer badges for parler
const parlerBadges = computed(() => {
  return (
    badges.value &&
    badges.value.filter((badge) =>
      [
        "early_adopter",
        "verified",
        "gold_verified",
        "parody_badge",
        "business_verified",
      ].includes(badge)
    )
  );
});

const isEmployee = computed(() => {
  return badges.value && badges.value.includes("employee" || "administrator");
});

const isMarc = computed(() => {
  return props.username === "Marc";
});

const isSean = computed(() => {
  return props.username === "uglydawg";
});

async function adminBanAndUnban() {
  const ulid = props.userId;
  const isBanned = props.isBanned;

  try {
    const response = isBanned
      ? await adminStore.unbanUser(ulid)
      : await adminStore.banUser(ulid);
    if (response && response.success) {
      alert(`${isBanned ? "Unbanned" : "Banned"} user successfully`);
    } else {
      alert(`Failed to ${isBanned ? "unban" : "ban"} user.`);
    }
  } catch (error) {
    console.error(`Error processing ${isBanned ? "unban" : "ban"}:`, error);
    alert(`Error: The operation could not be completed.`);
  }
}

const badgesData = ref([]);

onMounted(() => {
  document.addEventListener("click", (event) => {
    if (!popoverEvent.value) return;
    const popover = document.querySelector("ion-popover");
    if (
      !popover.contains(event.target) &&
      event.target !== popoverEvent.value
    ) {
      closeBadgePopover();
    }
  });
  if (globalStore.badgeTypes.length === 0) {
    globalStore.fetchBadgeTypes();
  }
  badgesData.value = globalStore.badgeTypes;
});

// Computed property to check if the current user is an employee
const isCurrentUserEmployee = computed(() => {
  return (
    authStore.currentUser.badges &&
    authStore.currentUser.badges.includes("employee")
  );
});

const isCurrentUserModerator = computed(() => {
  return (
    authStore.currentUser.badges &&
    authStore.currentUser.badges.includes("moderator")
  );
});

const uniqueBadgesData = computed(() => {
  const uniqueNames = new Set();
  return badgesData.value.filter((badge) => {
    if (!uniqueNames.has(badge.name)) {
      uniqueNames.add(badge.name);
      return true;
    }
    return false;
  });
});

// New popover state
const showManageBadgePopover = ref(false);
const manageBadgeEvent = ref(null);

const openManageBadgePopover = (event) => {
  manageBadgeEvent.value = event;
  showManageBadgePopover.value = true;
};

const closeManageBadgePopover = () => {
  showManageBadgePopover.value = false;
  areYouSure.value = false;
};

// Method to add or remove a badge
const addOrRemoveBadge = async (badgeName) => {
  //Remove capitalization
  const lowercaseUsername = props.username.toLowerCase();

  const method =
    badges.value && badges.value.includes(badgeName) ? "DELETE" : "POST";
  const action = method === "DELETE" ? "Removed" : "Added";
  const response = await adminStore.addOrRemoveBadge(
    lowercaseUsername,
    badgeName,
    method
  );

  if (response.success) {
    // Display success message
    alert(`${action} ${badgeName} badge successfully`);
    userStore.fetchUsersFromApi([props.userId]);

    // Close the popover
    showManageBadgePopover.value = false;
  } else {
    // Handle the error case - display the error message
    alert("The ${badgeName} badge was not ${action} due to an error");
  }
};

const parlerBadge = (name) => {
  switch (name) {
    case "moderator":
      return moderator;
    case "early_adopter":
      return early_adopter;
    case "verified":
      return verified_user;
    case "gold_verified":
      return influencer;
    case "new_user":
      return new_user;
    case "business_verified":
      return verified_business;
    case "parody_badge":
      return parody_badge;
    default:
      return verified_user;
  }
};

const badgeDesc = (name) => {
  // Find the badge object in badgesData array
  const badgeInfo = badgesData.value.find((badge) => badge.name === name);
  // Return the description if found, else a default message
  return badgeInfo
    ? badgeInfo.description
    : "No description available for this badge.";
};

const capitalize = (str) => {
  if (str === "gold_verified") {
    return "Gold Verified User";
  }
  if (str === "verified") {
    return "Verified User";
  }
  return str
    .split("_") // Split by underscore
    .map((word) =>
      word
        .split(" ") // Split by space in case there are spaces
        .map((w) => w.charAt(0).toUpperCase() + w.slice(1)) // Capitalize each word
        .join(" ")
    )
    .join(" "); // Join them back together with a space
};

// Popover state
const showBadgePopover = ref(false);
const popoverEvent = ref(null);
const showBadgeDesc = ref(false);
const descEvent = ref(null);
const selectedBadge = ref(null);

const openBadgePopover = (event) => {
  popoverEvent.value = event;
  showBadgePopover.value = true;
};

const closeBadgePopover = () => {
  showBadgePopover.value = false;
};
const openBadgeDesc = (event, badge) => {
  descEvent.value = event;
  showBadgeDesc.value = true;
  selectedBadge.value = badge;
};

onBeforeUnmount(() => {
  document.removeEventListener("click", closeBadgePopover);
});
</script>
<style scoped lang="scss">
.popover-content {
  padding: 10px;
  max-width: 300px;
}

ion-popover,
.firstPop {
  --width: 325px !important;
}

ion-content.min .badges {
  display: none;
}
</style>
