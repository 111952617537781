<template>
  <ion-modal
    :is-open="isOpened"
    :initial-breakpoint="0.75"
    :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
    @willDismiss="closeFeedbackModal"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title class="text-black dark:text-white">Enter your Feedback</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding text-black dark:text-white">
      <ion-textarea
        :auto-grow="true"
        :autofocus="true"
        rows="4"
        class="max-h-64 overflow-auto text-black dark:text-white"
        :counter="true"
        maxlength="2000"
        :spellcheck="true"
        autocapitalize="sentences"
        v-model="feedbackInput"
        placeholder="What's up?"
      />
      <div class="button-group cursor-pointer select-none">
        <ion-button :strong="true" @click="confirm">Confirm</ion-button>
        <ion-button color="medium" @click="closeFeedbackModal">Cancel</ion-button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import {
  IonTitle,
  IonModal,
  IonButton,
  IonTextarea,
  IonContent,
  IonHeader,
  IonToolbar,
} from "@ionic/vue";
import { ref } from "vue";
import { apiBackendAuthAxios } from "@/axiosAuth";

defineProps({
  isOpened: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["close", "showToast"]);
const feedbackInput = ref("");
const errorMessage = ref(null);
const toastOpen = ref(false);

const showToast = (message, color) => {
  emit("showToast", message, color);
};

function closeFeedbackModal() {
  emit("close");
}

async function confirm() {
  const feedback = {
    message: feedbackInput.value,
  };

  // Do not hit backend if textarea is empty
  if (feedback.message.trim().length === 0) {
    showToast("Error: Enter your feedback", "danger");
    return;
  }

  apiBackendAuthAxios
    .post("/feedback", feedback)
    .then(() => {
      showToast("Your feedback has been submitted.", "success");
      emit("close");
    })
    .catch((error) => {
      console.error(error);
      switch (error.response?.data?.error_type) {
        case "authentication":
          errorMessage.value = "Authentication Failed";
          break;
        case "Too many requests":
          errorMessage.value = "Too many attempts, try again in a few minutes";
          break;
        default:
          errorMessage.value = "Uh oh, something went wrong, try again";
      }
      showToast(`Error: ${errorMessage.value}.`, "danger");
    });
}
</script>
