<template>
  <teleport to="#modal-container">
    <div
      v-if="isModalVisible"
      ref="modalContainer"
      class="fixed inset-0 bg-black bg-opacity-75 z-[30] cursor-pointer select-none"
      @click="closeModal"
    >
      <div
        class="relative max-w-5/6 max-h-5/6 modal-container"
        ref="modalContent"
        @click.stop="handleContentClick"
      >
        <button
          v-if="modalContentUrls.length > 0 && arrowsVisible"
          class="absolute top-1/2 left-2 transform -translate-y-2/4 opacity-60 z-20"
          @click.stop.prevent="previousImage"
        >
          <ion-icon
            v-if="modalContentUrls.length > 0"
            :icon="caretBack"
            class="text-7xl previous-arrow"
          />
        </button>
        <button
          v-if="modalContentUrls.length > 0 && arrowsVisible"
          class="absolute top-1/2 right-2 transform -translate-y-2/4 opacity-60 z-20"
          @click.stop.prevent="nextImage"
        >
          <ion-icon
            v-if="modalContentUrls.length > 0"
            :icon="caretForward"
            class="text-7xl next-arrow"
          />
        </button>
        <div class="inline-block relative h-screen img-wrapper" @click.stop="hideArrows">
          <ion-img
            ref="imgRef"
            v-if="modalContentUrls.length > 0"
            :src="getImageSrc"
            class="object-contain"
          />
        </div>
        <div
          v-if="arrowsVisible"
          class="absolute top-2 right-2 m-2 p-1 bg-black/10 dark:bg-white/10 rounded-full"
        >
          <ion-icon
            :icon="closeSharp"
            size="xlarge"
            class="text-white text-4xl close-modal"
            @click.stop="closeModal"
          />
        </div>
      </div>
    </div>
  </teleport>
</template>
<script setup>

import { watch, ref, computed, onMounted, onBeforeUnmount } from "vue";
import { useEventBus } from "@vueuse/core";
import { IonIcon, IonImg, createGesture } from "@ionic/vue";
import { closeSharp, caretBack, caretForward } from "ionicons/icons";

const props = defineProps({
  isModalVisible: {
    type: Boolean,
    required: true,
  },
  modalContentUrls: {
    type: [Array, String],
    required: false,
  },
  currentImageIndex: {
    // Accept the current image index as a prop
    type: Number,
    default: 0,
    required: false,
  },
});

const emit = defineEmits(["closeModal", "nextImage", "previousImage"]);
const event = useEventBus("modalBus");
const arrowsVisible = ref(false);
const modalContent = ref(null);
const imgRef = ref(null);
const gestureX = ref(null);
const gestureY = ref(null);

onMounted(() => {
  if (imgRef.value && imgRef.value.$el) {
    // Gesture for X direction
    gestureX.value = createGesture({
      el: imgRef.value.$el,
      gestureName: "swipe-x",
      direction: "x",
      gesturePriority: 1, // Higher priority
      threshold: 15,
      canStart: () => true,
      onStart: (detail) => {},
      onMove: (detail) => {},
      onEnd: moveHandler,
    });

    gestureX.value.enable();

    // Gesture for Y direction
    gestureY.value = createGesture({
      el: imgRef.value.$el,
      gestureName: "swipe-y",
      direction: "y",
      gesturePriority: 0, // Lower priority
      threshold: 15,
      canStart: () => true,
      onStart: (detail) => {},
      onMove: (detail) => {},
      onEnd: moveHandler,
    });

    gestureY.value.enable();
  }
});

onBeforeUnmount(() => {
  if (gestureX.value) {
    gestureX.value.destroy();
  }
  if (gestureY.value) {
    gestureY.value.destroy();
  }
});
// Shared move handler function
function moveHandler(detail) {
  if (detail.deltaX < -150) {
    // Swipe left
    if (Array.isArray(props.modalContentUrls)) {
      nextImage();
      return;
    }
    closeModal();
  } else if (detail.deltaX > 150) {
    // Swipe right
    if (Array.isArray(props.modalContentUrls)) {
      previousImage();
      return;
    }
    closeModal();
  } else if (detail.deltaY > 150) {
    // Swipe down
    closeModal();
  } else if (detail.deltaY < -150) {
    // Swipe up
    closeModal();
  }
}

const getImageSrc = computed(() => {
  if (Array.isArray(props.modalContentUrls)) {
    arrowsVisible.value = true;
    return props.modalContentUrls[props.currentImageIndex];
  }
  arrowsVisible.value = false;
  return props.modalContentUrls;
});

function handleContentClick(event) {
  // If the click was on the image, toggle the arrows
  if (event.target.tagName.toLowerCase() === "ion-img") {
    hideArrows();
    event.preventDefault();
    return;
  }
  closeModal();
}

watch(
  () => props.isModalVisible,

  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      event.emit("modalBus");
    }
  }
);

function hideArrows() {
  if (Array.isArray(props.modalContentUrls)) {
    arrowsVisible.value = !arrowsVisible.value;

    return;
  }
  closeModal();
}

const nextImage = () => {
  emit("nextImage");
};

const previousImage = () => {
  emit("previousImage");
};

function closeModal() {
  emit("closeModal");
}
</script>

<style>
.modal-container {
  display: flex;
  justify-content: center;
  /* Horizontally center */
  align-items: center;
  /* Vertically center */
  width: 100vw;
  /* Full viewport width */
  height: 100vh;
  /* Full viewport height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  overflow-x: hidden;
  /* Prevent horizontal scrolling */
  padding: 10px;
  /* Optional padding */
  box-sizing: border-box;
  /* Include padding in width calculation */
}

.relative.max-w-5.max-h-5.modal-container {
  /* Use this class to ensure the contents are also centered */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Hide scrollbar for Webkit browsers */
.modal-container::-webkit-scrollbar {
  display: none;
  /* This will hide the scrollbar in Webkit browsers */
}

/* Hide scrollbar for IE, Edge, and Firefox */
.modal-container {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

ion-img {
  max-width: 100%;
  /* Image takes the full width of the container */
  height: auto;
  /* Maintain aspect ratio */
  object-fit: contain;
  /* Ensure the entire image is visible */
}

.tag-link {
  color: #8f0222;
  /* Additional styling as needed */
}

.space {
  display: inline-block;
  width: 0.3em;
  /* Adjust the width as per your preference */
}

.text-wrapper {
  position: relative;
  max-height: 100px;
  overflow: hidden;
}

.text-wrapper::after {
  content: "...";
  position: absolute;
  bottom: 0;
  right: 0;
  background: white;
  /* or whatever your background color is */
  padding-left: 5px;
  display: none;
  /* Hide by default */
}

.text-wrapper.show-more::after {
  display: block;
}

ion-icon.next-arrow {
  color: var(--secondary-color);
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

ion-icon.previous-arrow {
  color: var(--primary-color);
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

ion-icon.close-modal {
  filter: drop-shadow(3px 3px 2px rgba(0, 0, 0, 0.7));
}

.object-contain {
  max-height: inherit;
}

.img-wrapper {
  display: flex;
  max-width: 100vw;
  max-height: 100vh;
  padding: 0.5em;
}

</style>
