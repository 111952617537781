<template>
  <ion-modal
    :is-open="isOpen"
    :initial-breakpoint="1"
    :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
    @willDismiss="clearData"
  >
    <ion-header>
      <ion-toolbar> <ion-title>GIF Selector</ion-title> </ion-toolbar>
    </ion-header>
    <ion-content class="fixed-height-content">
      <div class="flex justify-between items-center p-2">
        <ion-searchbar
          v-model="searchQuery"
          @input="performSearch"
          @keyup.enter="performSearch"
          placeholder="Search"
        ></ion-searchbar>
        <ion-button @click="closeModal" color="light" class="cursor-pointer select-none"
          >Cancel</ion-button
        >
      </div>
      <div
        v-if="gifs.length"
        ref="gifScroller"
        class="grid grid-cols-2 gap-2 mt-2 overflow-y-auto gif-scroller"
        @scroll="checkScroll"
      >
        <img
          v-for="gif in gifs"
          :key="gif.id"
          class="w-full h-auto cursor-pointer select-none"
          :src="gif.images.preview_gif.url"
          alt="GIF"
          @click="selectGif(gif.images.original.url)"
        />
      </div>
      <div class="absolute inset-x-0 bottom-3 flex justify-center items-center">
        <ion-button
          color="primary"
          class="load-more-button cursor-pointer select-none"
          v-if="showLoadMoreButton"
          @click="loadMore"
        >
          Load More
        </ion-button>
      </div>
    </ion-content>
  </ion-modal>
</template>

<script setup>

import { ref, computed, onBeforeUnmount } from "vue";
import {
  IonButton,
  IonModal,
  IonSearchbar,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonContent,
} from "@ionic/vue";

const emits = defineEmits(["selected", "close"]);
const gifScroller = ref("null");
const searchQuery = ref("");
const gifs = ref([]);
const selectedGifs = ref([]);
const page = ref(1); // Current page
const limit = 20; // Number of GIFs per page
const showLoadMoreButton = ref(false);

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

function checkScroll() {
  const scrollableArea = gifScroller.value; // Access the ref directly
  if (scrollableArea) {
    // Calculate the scroll position relative to the scrollable area's height
    const scrollPosition = scrollableArea.scrollTop + scrollableArea.clientHeight;
    const scrollHeight = scrollableArea.scrollHeight;

    // If the scroll position is near the bottom, show the "Load More" button
    if (scrollHeight - scrollPosition < 200 && isThereMore) {
      showLoadMoreButton.value = true;
    } else {
      showLoadMoreButton.value = false;
    }
  }
}

function performSearch() {
  page.value = 1; // Reset page when performing a new search
  gifs.value = [];
  loadGifs();
}

function loadGifs() {
  const apiKey = "UnVym5jNqlIkr86UScWjeB3AQAgFk6Ps";
  const endpoint = `https://api.giphy.com/v1/gifs/search?q=${
    searchQuery.value
  }&api_key=${apiKey}&limit=${limit}&offset=${(page.value - 1) * limit}`;

  fetch(endpoint)
    .then((response) => response.json())
    .then((data) => {
      gifs.value = [...gifs.value, ...data.data]; // Append new GIFs to existing ones
      page.value++; // Increment page for the next load
    })
    .catch((error) => {
      console.error(error);
    });
}

function selectGif(url) {
  const media = {
    url: url,
    isVideo: false,
  };

  emits("selected", media);
  selectedGifs.value.push(url);
}

function closeModal() {
  gifs.value = [];
  searchQuery.value = "";
  showLoadMoreButton.value = false;
  page.value = 1; // Reset page when clearing data
  emits("close");
}

function clearData() {
  gifs.value = [];
  searchQuery.value = "";
  showLoadMoreButton.value = false;
  page.value = 1; // Reset page when clearing data
}

function loadMore() {
  loadGifs(); // Load the next page of GIFs
}

// Compute whether to show the "Load More" button
const isThereMore = computed(
  () => gifs.value.length % limit === 0 && gifs.value.length > 0
);

onBeforeUnmount(() => {
  gifs.value = [];
  searchQuery.value = "";
  showLoadMoreButton.value = false;
  page.value = 1; // Reset page when unmounting

  // Clear the selected GIFs
  selectedGifs.value = [];

  // Clear the scrollable area
  gifScroller.value = null;
});
</script>

<style scoped>
.fixed-height-content {
  --height: calc(100% - 48px);
  position: relative;
  /* Adjust the height as needed */
  --overflow: hidden;
}

.gif-scroller {
  overflow-y: auto;
  height: 92%;
  /* Make the gifScroller fill the available height */
}
</style>
