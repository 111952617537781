<template>
  <div
    @click.stop.prevent
    v-if="postStore.postsCache[props.parentItem.id]"
    class="flex items-center justify-center"
  >
    <template v-for="(type, idx) in ['thumbs_up', 'thumbs_down']" :key="idx">
      <div
        class="border border-gray-300 dark:border-gray-500 flex items-center justify-center space-x-1 px-1 h-6 min-w-12 text-[1rem] align-middle"
        :class="type === 'thumbs_up' ? 'rounded-l-full' : 'rounded-r-full'"
        @click="voteHandleReaction(type)"
      >
        <VoteIcon
          :title="type === 'thumbs_up' ? 'Upvote' : 'Downvote'"
          @click="voteHandleReaction(type)"
          :rotation="type === 'thumbs_down' ? 180 : 0"
          :active="post.userReaction === type"
          :type="type"
          :userReaction="post.userReaction"
          :isReactionPositive="isUserReactionPositive"
          :isReactionNegative="isUserReactionNegative"
        />
        <FormatCount
          v-if="
            (type === 'thumbs_down' && totalNegativeReactionCount > 0) ||
            (type === 'thumbs_up' && totalPositiveReactionCount > 0)
          "
          :count="
            type === 'thumbs_down'
              ? totalNegativeReactionCount
              : totalPositiveReactionCount
          "
          class="text-sm"
        />
      </div>
    </template>
    <div
      :id="['popover-reactions' + post.id]"
      @mousedown="handlePressStart"
      @mouseup="handlePressEnd"
      @touchstart="handlePressStart"
      @touchend="handlePressEnd"
      class="border border-gray-300 dark:border-gray-500 rounded-full mx-2 flex items-center justify-center space-x-1 px-1 h-6 min-w-12 text-[1rem] align-middle"
    >
      <div
        v-if="postStore.postsCache[props.parentItem.id]?.userReaction"
        class="h-inherit flex justify-center items-center"
      >
        <ion-icon
          v-if="
            postStore.postsCache[props.parentItem.id]?.userReaction === 'heart'
          "
          :icon="heart"
          class="text-red-500 align-middle"
          :class="{ 'wiggle-once': add, 'shrink-once': remove }"
        />
        <div
          class="inline-flex items-center h-1"
          :class="{ 'wiggle-once': add, 'shrink-once': remove }"
          v-else
        >
          {{
            getReactionValue(
              postStore.postsCache[props.parentItem.id]?.userReaction
            )
          }}
        </div>
      </div>
      <ion-icon v-else :icon="heartOutline" />
      <FormatCount
        v-if="totalReactionCount > 0"
        :count="totalReactionCount"
        class="text-sm"
      />
    </div>
  </div>
  <ion-popover
    :is-open="popoverReactionsOpen"
    :keep-contents-mounted="true"
    :arrow="false"
    :event="reactions"
    @didDismiss="popoverReactionsOpen = false"
    side="top"
    alignment="center"
    class="reactions"
  >
    <div
      class="grid grid-cols-6 overflow-hidden items-center space-x-1"
      :class="showAllReactions ? 'p-1' : ''"
    >
      <div
        class="flex cursor-pointer select-none"
        v-for="(reaction, index) in displayedReactions"
        :key="reaction.name"
        @click="handleReaction(reaction?.name)"
        :class="showAllReactions ? 'first:pl-1' : ''"
      >
        <ion-icon
          v-if="reaction.name === 'heart'"
          :icon="heart"
          class="text-red-500 align-middle pl-2 text-2xl"
        />
        <div v-else class="p-1 text-2xl">
          {{ getReactionValue(reaction.name) }}
        </div>
      </div>
      <div
        @click="toggleShowMore"
        class="cursor-pointer h-10 w-10 flex items-center justify-center"
      >
        <ion-icon :icon="showMoreIcon" class="text-2xl text-gray-500" />
      </div>
    </div>
  </ion-popover>
</template>

<script setup>
import { ref, computed, onUnmounted, onBeforeUnmount } from "vue";
import { usePostStore } from "@/stores/post";
import { useGlobalStore } from "@/stores/global";
import { IonPopover, IonIcon } from "@ionic/vue";
import FormatCount from "@/components/FormatCount.vue";
import VoteIcon from "@/components/tenantcomponents/parler/VoteIcon.vue";
import {
  addCircleOutline,
  removeCircleOutline,
  heart,
  heartOutline,
} from "ionicons/icons";

const emit = defineEmits(["showToast", "show-details-reactions", "closeToast"]);
const postStore = usePostStore();
const props = defineProps({
  parentItem: Object,
  isNestedFeed: {
    type: Boolean,
    default: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
  },
  toastOpen: {
    type: Boolean,
    default: false,
  },
  quote: {
    type: Boolean,
    default: false,
  },
});

const reactionData = computed(() => {
  return globalStore.reactionTypes;
});

const post = computed(() => {
  return postStore.postsCache[props.parentItem.id];
});

const userReaction = computed(() => {
  return post.value.userReaction;
});

const isUserReactionPositive = computed(() => {
  return Boolean(
    globalStore.reactionTypes.some(
      (reaction) =>
        reaction.name === userReaction.value && reaction.category === "positive"
    )
  );
});

const isUserReactionNegative = computed(() => {
  return Boolean(
    globalStore.reactionTypes.some(
      (reaction) =>
        reaction.name === userReaction.value && reaction.category === "negative"
    )
  );
});

let pressTimer = null;
let isLongPress = false;

let startX, startY, endX, endY;

function handlePressStart(event) {
  isLongPress = false;
  const touch = event.touches ? event.touches[0] : event;
  startX = touch.clientX;
  startY = touch.clientY;

  pressTimer =
    totalReactionCount.value &&
    setTimeout(() => {
      emit("show-details-reactions");
      isLongPress = true;
      pressTimer = null;
    }, 500); // 500ms for long press
}

function handlePressEnd(event) {
  const touch = event.changedTouches ? event.changedTouches[0] : event;
  endX = touch.clientX;
  endY = touch.clientY;

  // if the touchend event is not within the same element as the touchstart event, not a touchend event
  if (startX !== endX || startY !== endY) {
    return;
  }

  if (pressTimer) {
    clearTimeout(pressTimer);
    pressTimer = null;
  }

  if ((!isLongPress && !popoverReactionsOpen.value) || props.quote) {
    openReactionsPopover(event); // Considered as a short click
  }
}

// Lifecycle hook to clean up when the component is destroyed
onUnmounted(() => {
  if (pressTimer) {
    clearTimeout(pressTimer);
  }
  if (popoverReactionsOpen.value) {
    popoverReactionsOpen.value = false;
  }
});

const globalStore = useGlobalStore();
const loading = ref(false);
const popoverReactionsOpen = ref(false);

const showAllReactions = ref(false);

const displayedReactions = computed(() => {
  return showAllReactions.value
    ? reactionData.value
    : reactionData.value.slice(0, 5);
});

const toggleShowMore = () => {
  showAllReactions.value = !showAllReactions.value;
};

const showMoreIcon = computed(() => {
  return showAllReactions.value ? removeCircleOutline : addCircleOutline;
});

const totalReactionCount = computed(() => {
  const reactions = post.value?.postEngagement?.reactions || [];
  let reactionCount = 0;

  if (Array.isArray(reactions)) {
    for (const reaction of reactions) {
      reactionCount += reaction?.count ?? 0;
    }
  }

  return reactionCount;
});

const totalPositiveReactionCount = computed(() => {
  const positiveReactions = globalStore.reactionTypes.filter(
    (reaction) => reaction.category === "positive"
  );

  const reactions = post.value?.postEngagement?.reactions || [];
  let positiveCount = 0;

  if (Array.isArray(reactions) && Array.isArray(positiveReactions)) {
    for (const reaction of reactions) {
      if (reaction && positiveReactions.some((r) => r.name === reaction.name)) {
        positiveCount += reaction?.count ?? 0;
      }
    }
  }

  return positiveCount;
});

const totalNegativeReactionCount = computed(() => {
  const negativeReactions = globalStore.reactionTypes.filter(
    (reaction) => reaction.category === "negative"
  );
  const reactions = post.value?.postEngagement?.reactions || [];
  let negativeCount = 0;

  if (Array.isArray(reactions) && Array.isArray(negativeReactions)) {
    for (const reaction of reactions) {
      if (reaction && negativeReactions.some((r) => r.name === reaction.name)) {
        negativeCount += reaction?.count ?? 0;
      }
    }
  }

  return negativeCount;
});

let reactions;

const openReactionsPopover = (e) => {
  e.preventDefault();
  reactions = e;
  popoverReactionsOpen.value = true;
};

const getReactionValue = (reactionName) => {
  const reaction = reactionData.value.find((r) => r.name === reactionName);
  return reaction ? reaction.value : "";
};

async function voteHandleReaction(type) {
  if (loading.value) {
    return; // Do nothing if the function is already running
  }
  loading.value = true; // Set loading state to true
  closeToast();
  try {
    if (
      type === "thumbs_up" &&
      userReaction.value &&
      isUserReactionPositive.value
    ) {
      // Remove the reaction
      await postStore.updatePostReaction(
        post.value.id,
        userReaction.value,
        "delete"
      );
      updateReaction(userReaction.value, "delete");
      showToast("Your reaction has been removed.", "success");
    } else if (
      type === "thumbs_down" &&
      userReaction.value &&
      isUserReactionNegative.value
    ) {
      // Remove the reaction
      await postStore.updatePostReaction(
        post.value.id,
        userReaction.value,
        "delete"
      );
      updateReaction(userReaction.value, "delete");
      triggerAnimation("remove");
      showToast("Your reaction has been removed.", "success");
    } else if (userReaction.value) {
      // Add the reaction
      await postStore.updatePostReaction(
        post.value.id,
        userReaction.value,
        "delete"
      );
      updateReaction(userReaction.value, "delete");

      await postStore.updatePostReaction(post.value.id, type, "add");
      updateReaction(type, "add");
      triggerAnimation("add");
      showToast("Your reaction has been changed.", "success");
    } else {
      // Add the reaction
      await postStore.updatePostReaction(post.value.id, type, "add");
      updateReaction(type, "add");
      triggerAnimation("add");
      showToast("Your reaction has been added.", "success");
    }
  } catch (error) {
    console.error(error);
    showToast("An unexpected error occurred.", "danger");
  } finally {
    loading.value = false; // Reset loading state to false
  }
}

async function handleReaction(reactionType) {
  if (loading.value) {
    return; // Do nothing if the function is already running
  }

  loading.value = true; // Set loading state to true

  try {
    let currentReaction =
      postStore.postsCache[props.parentItem.id].userReaction;

    if (currentReaction) {
      // Check if the selected reaction is the same as the user's current reaction
      if (reactionType === currentReaction) {
        // Update the postStore's reactions
        await postStore.updatePostReaction(
          post.value.id,
          reactionType,
          "delete"
        );

        updateReaction(reactionType, "delete");
        triggerAnimation("remove");
        showToast("Your reaction has been removed.", "success");
      } else {
        // Update the postStore's reactions for the previous reaction
        await postStore.updatePostReaction(
          post.value.id,
          currentReaction,
          "delete"
        );
        updateReaction(currentReaction, "delete");

        await postStore.updatePostReaction(post.value.id, reactionType, "add");
        updateReaction(reactionType, "add");
        triggerAnimation("add");
        showToast("Your reaction has been changed.", "success");
      }
    } else {
      // Update the postStore's reactions for the new reaction
      await postStore.updatePostReaction(post.value?.id, reactionType, "add");
      updateReaction(reactionType, "add");
      triggerAnimation("add");
      showToast("Your reaction has been added.", "success");
    }
  } catch (error) {
    // Handle any unexpected errors
    console.error(error, "ERROR)");
    showToast("An unexpected error occurred.", "danger");
  } finally {
    loading.value = false; // Reset loading state to false
    popoverReactionsOpen.value = false; // Close the popover
  }
}
function updateReaction(reactionType, action) {
  // check if there reaction is already in the reactions array
  const reactions =
    postStore.postsCache[props.parentItem.id].postEngagement.reactions;

  const reactionIndex = reactions.findIndex((r) => r.name === reactionType);

  if (reactionIndex !== -1) {
    // if the reaction is found
    if (action === "add") {
      reactions[reactionIndex].count++;
      postStore.postsCache[post.value.id].userReaction = reactionType;
    } else if (action === "delete") {
      if (reactions[reactionIndex].count === 1) {
        // if the count is at 1 and the action is delete, remove the reaction from the array
        reactions.splice(reactionIndex, 1);
      } else {
        reactions[reactionIndex].count--;
      }
      postStore.postsCache[post.value.id].userReaction = null;
    }
  } else {
    if (action === "delete") {
      return;
    }
    // if the reaction is not found, add it to the reactions array
    postStore.postsCache[props.parentItem.id].postEngagement.reactions.push({
      count: 1,
      name: reactionType,
    });

    postStore.postsCache[post.value.id].userReaction = reactionType;
  }
  postStore.updatePostInDB(postStore.postsCache[post.value.id]);
}

// Function to display the toast
function showToast(message, color) {
  if (props.toastOpen) {
    closeToast();
  }
  emit("showToast", { message, color });
}

function closeToast() {
  emit("closeToast");
}

const add = ref(false);
const remove = ref(false);
const animationTimer = ref(null);

function triggerAnimation(type) {
  if (navigator.vibrate) {
    navigator.vibrate([100, 50, 100]);
  }
  animationTimer.value = setTimeout(() => {
    if (type === "add") {
      add.value = true;
      remove.value = false;
    } else {
      remove.value = true;
      add.value = false;
    }
  }, 300); // 300ms for the animation
  add.value = false;
  remove.value = false;
}

onBeforeUnmount(() => {
  if (popoverReactionsOpen.value) {
    popoverReactionsOpen.value = false;
  }
  if (loading.value) {
    loading.value = false;
  }
  if (animationTimer.value) {
    clearTimeout(animationTimer.value);
  }
});
</script>

<style scoped>
ion-popover {
  --backdrop-opacity: 0.6;
  --box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.6);
  --width: 280px;
  --min-height: 40px;
}

ion-popover::part(content) {
  border-radius: 2rem;
}

.custom-border {
  border: 1px solid !important;
}

.h-inherit {
  height: inherit;
}

@keyframes wiggle {
  0%,
  100% {
    transform: rotate(0deg) scale(1);
  }
  25% {
    transform: rotate(5deg) scale(1.3);
  }
  50% {
    transform: rotate(-5deg) scale(1.3);
  }
  75% {
    transform: rotate(5deg) scale(1.3);
  }
}

@keyframes shrink {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.8);
  }
}

.wiggle-once {
  animation: wiggle 1s ease-in-out;
}

.shrink-once {
  animation: shrink 0.5s ease-in-out;
}
</style>
