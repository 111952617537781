// @ts-check

import { defineStore } from "pinia";
import { apiBackendAuthAxios } from "@/axiosAuth.js";
import { useAuthStore } from "@/stores/auth.js";
import { useGlobalStore } from "@/stores/global.js";
import { usePostStore } from "@/stores/post.js";
import axios from "axios";
import Dexie from "dexie";

const LOADING_STATE = {
  name: "Loading...",
  username: "Loading...",
  avatar:
    "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
  cover:
    "https://upload.wikimedia.org/wikipedia/commons/7/7c/Profile_avatar_placeholder_large.png",
  bio: "Loading...",
};

export const useUserStore = defineStore({
  id: "userStore",
  state: () => ({
    users: {},
    lastUpdateAt: "epochtimestamp",
    cancelTokenSource: null,
    following: [],
    friends: [],
    followers: [],
    followingCursor: null,
    friendsCursor: null,
    followersCursor: null,
    groupMembers: [],
    recommendedUsers: [],
    fetchNewRecommendedUsers: false,
  }),

  actions: {
    getUserDb() {
      const authStore = useAuthStore();
      const userId = authStore.currentUser?.ulid;

      if (!userId) {
        console.error("getUserDb - No UserId found");
        return null; // Return null or handle this scenario appropriately
      }

      try {
        const userDb = new Dexie(`UserDatabase_${userId}`);

        userDb.version(1).stores({
          users:
            "ulid,name,username,avatar,cover,bio,ProfileEngagement,followers,following,friendCount,postCount,website,updatedAtEpoch,updatedAt,badges,cachedAt,lastActiveAt",
          metadata: "key,value",
        });

        return userDb;
      } catch (error) {
        console.error(`Failed to open IndexedDB for userId ${userId}:`, error);
        throw error;
      }
    },

    deleteUserDb() {
      const authStore = useAuthStore();
      const userId = authStore.currentUser?.ulid;
      if (userId) {
        Dexie.delete(`UserDatabase_${userId}`)
          .then(() => {})
          .catch((error) => {
            console.error(`Failed to delete IndexedDB: ${error}`);
          });
      } else {
        console.error("User ID is undefined or null");
      }
    },

    async fetchUserConnections(username, type) {
      try {
        const encodedUsername = encodeURIComponent(username);
        const response = await apiBackendAuthAxios.get(
          `/profile/${encodedUsername}/${type}`,
          {
            params: { cursor: this[type + "Cursor"] },
          }
        );

        const userIds = response.data.data.map((user) => user.ulid);

        // Combine existing and new user IDs, then filter duplicates using Set
        this[type] = [...new Set([...this[type], ...userIds])];

        this[type + "Cursor"] = response.data.next_cursor;

        await this.fetchUsersByIds(userIds);

        return response.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchGroupMembers(groupId, nextCursor) {
      try {
        const response = await apiBackendAuthAxios.get(
          `/group/${groupId}/members`,
          {
            params: { cursor: nextCursor },
          }
        );

        const userIds = response.data.data.map((user) => user.id);

        if (this.groupMembers[groupId]) {
          const filteredUserIds = userIds.filter(
            (id) => !this.groupMembers[groupId].includes(id)
          );
          this.groupMembers[groupId] = [
            ...this.groupMembers[groupId],
            ...filteredUserIds,
          ];
        } else {
          this.groupMembers[groupId] = userIds;
        }
        await this.fetchUsersByIds(userIds);

        return response.data.nextCursor;
      } catch (error) {
        console.error(error);
      }
    },

    async removeGroupMember(groupId, userId) {
      try {
        await apiBackendAuthAxios.post(`/group/ban`, {
          user_id: userId,
          group_id: groupId,
        });
      } catch (error) {
        return error;
      }
    },

    async unbanFromGroup(groupId, userId) {
      try {
        await apiBackendAuthAxios.post(`/group/unban`, {
          user_id: userId,
          group_id: groupId,
        });
      } catch (error) {
        return error;
      }
    },

    async removeUserFromConnection(ulid, type) {
      this[type] = this[type].filter((id) => id !== ulid);
    },

    async updateUserInDB(user) {
      const userDb = this.getUserDb();

      // Clone the user to a plain object, strip reactivity
      const cachedUser = JSON.parse(
        JSON.stringify({
          ...user,
          cachedAt: new Date().toISOString(),
        })
      );

      // Try/catch to handle and log any errors that occur during the operation
      try {
        await userDb.table("users").put(cachedUser);
      } catch (error) {
        console.error("Failed to put user in IndexedDB", error);
        // You could throw the error to handle it further up in your application logic
        throw error;
      }
    },

    // Update Multiple Users in IndexedDB
    async updateUsersInDB(users) {
      const userDb = this.getUserDb();

      const cachedUsers = users.map((user) => ({
        ...user,
        cachedAt: new Date().toISOString(),
      }));

      try {
        await userDb.table("users").bulkPut(cachedUsers);
      } catch (error) {
        console.error("Failed to bulk put users in IndexedDB", error);
        throw error;
      }
    },

    async updateLastActiveAt(userId, lastActiveAt) {
      const userDb = this.getUserDb(); // Obtain the user-specific Dexie instance

      if (
        userId === null ||
        lastActiveAt === null ||
        userId === undefined ||
        lastActiveAt === undefined
      ) {
        return;
      }

      try {
        await userDb.table("users").update(userId, { lastActiveAt });

        // Update the local state
        if (this.users[userId]) {
          this.users[userId].lastActiveAt = lastActiveAt;
        } else {
          //Pull from indexDB
          const user = await userDb.table("users").get(userId);
          if (user) {
            this.users[userId] = user;
            this.users[userId].lastActiveAt = lastActiveAt;
          }
        }
      } catch (error) {
        console.error(
          `Failed to update lastActiveAt for user ${userId}: ${error.message}`
        );
        throw error;
      }
    },

    async clearStaleData() {
      const userDb = this.getUserDb(); // Obtain the user-specific Dexie instance
      const threshold = new Date();
      threshold.setHours(threshold.getHours() - 24); // Set the threshold for stale data

      if (!userDb.table("users")) {
        return;
      }
      // Delete all users that were cached before the threshold
      await userDb
        .table("users")
        .where("cachedAt")
        .below(threshold.toISOString())
        .delete();
    },

    userInvalidation(userIds) {
      // Iterate over postIds and delete each from postsCache
      userIds.forEach((id) => {
        delete this.users[id];
      });
    },

    async fetchUsersByIds(userIds) {
      if (!userIds) {
        console.error("Invalid userId provided");
        return null;
      }

      // Remove duplicate IDs and nulls
      const uniqueIds = [...new Set(userIds)].filter((id) => id);
      const fetchedUsers = {};

      // Check local state first
      const idsToFetchFromIndexedDB = uniqueIds.filter((id) => {
        if (this.users[id]) {
          fetchedUsers[id] = this.users[id];
          return false;
        }
        return true;
      });

      if (idsToFetchFromIndexedDB.length > 0) {
        const userDb = this.getUserDb();
        // Fetch multiple users from IndexedDB in one query
        const usersFromIndexedDB = await userDb
          .table("users")
          .where("ulid")
          .anyOf(idsToFetchFromIndexedDB)
          .toArray();
        usersFromIndexedDB.forEach((user) => {
          // retain last active at and cached at
          if (this.users[user.ulid]) {
            if (this.users[user.ulid].lastActiveAt) {
              user.lastActiveAt = this.users[user.ulid].lastActiveAt;
            }
            if (this.users[user.ulid].cachedAt) {
              user.cachedAt = this.users[user.ulid].cachedAt;
            }
          }
          this.users[user.ulid] = user;

          fetchedUsers[user.ulid] = user;
        });
      }

      // Determine which IDs still need to be fetched from API
      const idsToFetchFromApi = idsToFetchFromIndexedDB.filter(
        (id) => !fetchedUsers[id]
      );

      // Batch the API requests for idsToFetchFromApi
      const BATCH_SIZE = 20;
      for (let i = 0; i < idsToFetchFromApi.length; i += BATCH_SIZE) {
        const batch = idsToFetchFromApi.slice(i, i + BATCH_SIZE);

        if (batch.length > 0) {
          try {
            const response = await apiBackendAuthAxios.post("/profile", {
              ulids: batch,
            });

            const newUsers = response.data[0].data;

            // Add new users to IndexedDB and local state
            newUsers.forEach((user) => {
              this.updateUserInDB(user);
              this.addUser(user);
              fetchedUsers[user.ulid] = user;
            });
          } catch (error) {
            console.error(`Error fetching users by IDs from API: ${error}`);
            throw error;
          }
        }
      }

      return fetchedUsers;
    },

    async fetchUsersFromApi(userIds) {
      const authStore = useAuthStore();
      const currentUserUlid = authStore.currentUser.ulid;

      // Check if current user's ulid is in the userIds array
      if (userIds.includes(currentUserUlid)) {
        // Update the local state with the current user's updates
        authStore.setUser();
      }

      // Deduplicate the userIds array
      const uniqueUserIds = [...new Set(userIds)];

      try {
        const response = await apiBackendAuthAxios.post("/profile", {
          ulids: uniqueUserIds,
        });
        const newUsers = response.data[0].data;
        this.addUsers(newUsers);
        return newUsers;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    async fetchUserById(userId) {
      const userDb = this.getUserDb(); // Obtain the user-specific Dexie instance

      if (!userId) {
        console.error("Invalid userId provided");
        return null; // or throw an error
      }

      // First, try to get the user from IndexedDB
      try {
        let user = await userDb.table("users").get(userId);
        if (user) {
          // If user is found in IndexedDB, return it
          return user;
        } else {
          // If user is not found in IndexedDB, fetch from API
          const response = await apiBackendAuthAxios.get(`/profile/${userId}`);
          user = response.data.data;
          // Store the user in IndexedDB for future access
          await this.updateUserInDB(user);
          return user;
        }
      } catch (error) {
        console.error(`Failed to fetch user: ${error.message}`);
        throw error;
      }
    },
    async fetchUserByUsername(username, fresh = false) {
      const userDb = this.getUserDb(); // Obtain the user-specific Dexie instance

      try {
        // Attempt to find the user in IndexedDB by username
        let user = await userDb
          .table("users")
          .where("username")
          .equals(username)
          .first();

        if (user && !fresh) {
          // User found in IndexedDB, return it
          return user;
        } else {
          const encodedUsername = encodeURIComponent(username);

          // User not found in IndexedDB, fetch from API
          const response = await apiBackendAuthAxios.get(
            `/profile/${encodedUsername}`
          );
          user = response.data.data;

          // Store the user in IndexedDB for future use
          await this.addUser(user);
          return user;
        }
      } catch (error) {
        console.error(`Failed to fetch user by username: ${error.message}`);
        throw error;
      }
    },
    async updateUser(ulid, updates) {
      try {
        const response = await apiBackendAuthAxios.patch(`/user`, updates);
        if (!response.data.success) {
          this.removeUser(ulid);
          throw new Error(response.data.message);
        }
        await this.fetchUsersFromApi([ulid]);
        return response;
      } catch (error) {
        console.error(`Failed to update user: ${error.message}`);
        throw error;
      }
    },
    async searchUsers(query) {
      this.cancelTokenSource = axios.CancelToken.source();
      try {
        const response = await apiBackendAuthAxios.post(
          "/search/autocomplete/user",
          { q: query },
          { cancelToken: this.cancelTokenSource.token }
        );

        return response.data.data;
      } catch (error) {
        if (axios.isCancel(error)) {
          console.error("Request cancelled:", error.message);
        } else {
          console.error("Error searching username:", error);
        }
        return [];
      }
    },

    async newSearchUsers(query, count = 10) {
      try {
        const response = await apiBackendAuthAxios.post("/search/user", {
          q: query,
          limit: count,
        });
        return response.data.data;
      } catch (error) {
        console.error("Error searching username:", error);
        return [];
      }
    },

    async deleteUser() {
      try {
        const response = await apiBackendAuthAxios.delete("/user");
        return response.data;
      } catch (error) {
        console.error("Error deleting user:", error);
        return null;
      }
    },

    async userAction(username, userId, type, currentUsername, violation) {
      const postStore = usePostStore();

      let action;
      if (type === "block" || type === "unblock") {
        action = "isBlocked";
      } else if (type === "mute" || type === "unmute") {
        action = "isMuted";
      }

      if (type === "block" || type === "mute") {
        try {
          const response = await apiBackendAuthAxios.put(
            `/profile/${username}/${type}`
          );
          postStore.removePostsFromCache(userId);
          this.users[userId].ProfileEngagement[action] = true;
          return response.data;
        } catch (error) {
          console.error(`Error ${type}ing user:`, error);
          return error;
        }
      } else if (type === "unblock" || type === "unmute") {
        if (type === "unblock") {
          type = "block";
        } else {
          type = "mute";
        }
        try {
          const response = await apiBackendAuthAxios.delete(
            `/profile/${username}/${type}`
          );
          postStore.removePostsFromCache(userId);
          this.users[userId].ProfileEngagement[action] = false;
          return response.data;
        } catch (error) {
          console.error(`Error ${type}ing user:`, error);
          return error;
        }
      } else if (type === "report") {
        try {
          const report = {
            message: `User ${username} has been reported by ${currentUsername} for ${violation}. Please review their account. User ID: ${userId}`,
          };
          const response = await apiBackendAuthAxios.post("/feedback", report);
          postStore.removePostsFromCache(userId);
          return response.data;
        } catch (error) {
          console.error(`Error reporting user:`, error);
          return error;
        }
      }
    },

    async fetchTrendingUsers(onboarding = false) {
      if (this.recommendedUsers.length > 0 || this.fetchNewRecommendedUsers) {
        return this.recommendedUsers;
      }
      try {
        const response = await apiBackendAuthAxios.get(
          "/trending/users/last24"
        );

        const users = response.data.users;

        if (!onboarding) {
          if (response.data.users.length === 0) {
            return [];
          }

          await this.fetchUsersByIds(users);

          return users;
        }

        const recommendedResponse = await apiBackendAuthAxios.get(
          "/recommended/users"
        );

        const newUsers = recommendedResponse.data.recommended_users;

        newUsers.forEach((user) => {
          if (!users.includes(user)) {
            users.push(user);
          }
        });

        await this.fetchUsersByIds(newUsers);

        this.recommendedUsers = newUsers;

        this.fetchNewRecommendedUsers = false;

        return users;
      } catch (error) {
        console.error("Error fetching trending users:", error);
        return [];
      }
    },

    async fetchGroupBannedUsers(groupId) {
      try {
        const response = await apiBackendAuthAxios.post(`/group/banned-users`, {
          group_id: groupId,
        });

        const userIds = response.data.data.map((user) => user.id);

        this.fetchUsersByIds(userIds);

        return userIds;
      } catch (error) {
        return error;
      }
    },

    async addUser(user) {
      const authStore = useAuthStore();
      try {
        await this.updateUserInDB(user);
        //retain last active at and cached at
        if (this.users[user.ulid]) {
          if (this.users[user.ulid].lastActiveAt) {
            user.lastActiveAt = this.users[user.ulid].lastActiveAt;
          }
          if (this.users[user.ulid].cachedAt) {
            user.cachedAt = this.users[user.ulid].cachedAt;
          }
        }
        this.users[user.ulid] = user; // Update local state
      } catch (error) {
        console.error("Failed to add/update user in IndexedDB:", error);
      }
    },

    async addUsers(users) {
      // If only one user, use the addUser method for a single entry.
      if (users.length === 1) {
        this.addUser(users[0]);
        return;
      }

      const userDb = this.getUserDb(); // Obtain the user-specific Dexie instance

      try {
        // Directly use updateUsersInDB without starting a manual transaction.
        await this.updateUsersInDB(users);
        //retain last active at and cached at
        users.forEach((user) => {
          if (this.users[user.ulid]) {
            if (this.users[user.ulid].lastActiveAt) {
              user.lastActiveAt = this.users[user.ulid].lastActiveAt;
            }
            if (this.users[user.ulid].cachedAt) {
              user.cachedAt = this.users[user.ulid].cachedAt;
            }
          }
          this.users[user.ulid] = user; // Update local state
        });
      } catch (error) {
        console.error("Failed to add/update users in batch:", error);
      }
    },

    async removeUser(ulid) {
      const userDb = this.getUserDb(); // Obtain the user-specific Dexie instance

      try {
        // Remove from IndexedDB
        await userDb.table("users").delete(ulid);
        // Update local state
        delete this.users[ulid];
      } catch (error) {
        console.error(`Failed to remove user: ${error.message}`);
        // Handle the error appropriately
      }
    },
    cancelSearch() {
      if (this.cancelTokenSource) {
        this.cancelTokenSource.cancel("Search request cancelled");
        this.cancelTokenSource = null;
      } else {
        console.warn("Attempted to cancel a search that was not in progress.");
      }
    },
    setMuteBlockCoolDown(ulid, timestamp) {
      if (this.users[ulid]) {
        this.users[ulid].muteBlockCoolDown = timestamp;
      } else {
        console.warn(
          `User with ID ${ulid} not found. Could not set muteBlockCoolDown.`
        );
      }
    },
    getMuteBlockCoolDown(ulid) {
      return this.users[ulid]?.muteBlockCoolDown || null;
    },

    async checkUsernameCache(username, singleCheck = false) {
      const userDb = this.getUserDb(); // Obtain the user-specific Dexie instance

      try {
        if (this.users[username]) {
          return this.users[username];
        }

        const user = await userDb
          .table("users")
          .where("username")
          .equals(username)
          .first();
        if (user) {
          return user.username;
        }

        // Prepare the Axios request configuration
        let config = {
          method: "post",
          url: "/search/autocomplete/user",
          data: { q: username },
        };

        // Add cancel token to the configuration if singleCheck is false
        if (!singleCheck) {
          config = { ...config, cancelToken: this.cancelTokenSource.token };
        }

        const response = await apiBackendAuthAxios(config);
        return response.data.data;
      } catch (error) {
        console.error(`Failed to check username cache: ${error.message}`);
        throw error;
      }
    },

    async checkUsernameApi(username) {
      const response = await apiBackendAuthAxios.post("/user/checkUsername", {
        username,
      });
      return response.data;
    },
    async checkFollowing(userId, currentUsername) {
      const globalStore = useGlobalStore();

      // Check if the userId is already in the following array
      if (globalStore.following.includes(userId)) {
        // If it's already in the array, no need to make the API call
        return;
      }

      try {
        // If not in the array, fetch the current user's following list
        const response = await apiBackendAuthAxios.get(
          `/profile/${currentUsername}/following`,
          {}
        );
        // Create an array of 'ulid' values from the response data
        const followingUlidList = response.data.data.map((user) => user.ulid);

        // Add all ulid values to the following array that are not already present
        for (const ulid of followingUlidList) {
          if (!globalStore.following.includes(ulid)) {
            globalStore.following.push(ulid);
          }
        }
      } catch (error) {
        console.error("error", error);
        throw error; // Throw the error in case of API call failure
      }
    },

    async followUser(username, userId) {
      const authStore = useAuthStore();
      const globalStore = useGlobalStore();
      const postStore = usePostStore();

      const encodedUsername = encodeURIComponent(username);

      try {
        // Make an API request to toggle the follow status
        let res = await apiBackendAuthAxios.put(
          `/profile/${encodedUsername}/follow`
        );

        authStore.currentUser.following++; // Increase followers count

        if (this.users[userId] && this.users[userId].ProfileEngagement) {
          this.users[userId].ProfileEngagement.isFollowing = true;
          this.users[userId].followers++;
          if (this.users[userId].ProfileEngagement.isFollowingYou) {
            this.users[userId].friendCount++;
          }

          const user = this.users[userId];

          this.updateUserInDB(user);
        }

        if (authStore.currentUser.following === 0) {
          postStore.fetchFeedData("following");
        } else {
          postStore.refreshFeedMapsOnNextFetch = true;
        }
        return res;
      } catch (error) {
        console.error(error);
        return error;
      }
    },

    async unfollowUser(username, userId) {
      const authStore = useAuthStore();
      const postStore = usePostStore();

      const encodedUsername = encodeURIComponent(username);

      try {
        // Make an API request to toggle the follow status
        await apiBackendAuthAxios.delete(`/profile/${encodedUsername}/follow`);

        authStore.currentUser.following--; // Decrease followers count

        this.users[userId].ProfileEngagement.isFollowing = false;
        this.users[userId].followers--;
        if (this.users[userId].ProfileEngagement.isFollowingYou) {
          this.users[userId].friendCount--;
        }

        const user = this.users[userId];

        this.updateUserInDB(user);

        this.following = this.following.filter((id) => id !== userId);
        this.friends = this.friends.filter((id) => id !== userId);

        postStore.refreshFeedMapsOnNextFetch = true;
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    getUser: (state) => (id) => state.users[id] || LOADING_STATE,
    getUsername: (state) => (id) =>
      state.users[id]?.username || LOADING_STATE.username,
    getAvatar: (state) => (id) =>
      state.users[id]?.avatar || LOADING_STATE.avatar,
    getName: (state) => (id) => state.users[id]?.name || LOADING_STATE.name,
    getBadges: (state) => (id) => state.users[id]?.badges,
    getProfileEngagement: (state) => (id) => state.users[id]?.ProfileEngagement,
  },
});
