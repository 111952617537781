<template :key="`owner-${id}`">
  <ion-icon
    :icon="ellipsisHorizontalSharp"
    v-if="
      authStore.currentUser.ulid === userId || authStore.currentUser.ulid === repostUserId
    "
    :id="['popover-Up' + id]"
    @click.stop.prevent="openPopover($event)"
    :disabled="popoverOpen === true"
    class="p-1 text-xl cursor-pointer select-noner"
  >
  </ion-icon>
  <ion-popover
    :is-open="popoverOpen"
    :event="deletePostEvent"
    @didDismiss="popoverOpen = false"
    dismiss-on-select="true"
    side="left"
    alignment="start"
  >
    <ion-list nested lines="none" class="cursor-pointer select-none">
      <ion-item v-if="userId === currId" @click="pinPost(id)">
        <ion-icon :icon="pin" class="pr-4" />
        <p v-if="id === pinnedPost">Unpin Post</p>
        <p v-else>Pin Post</p>
      </ion-item>
      <ion-item @click="deletePost(id)">
        <ion-icon slot="start" :icon="trashOutline"></ion-icon>
        Delete
      </ion-item>
     <ion-item
        @click.stop.prevent="copyPostUrlAndShowToast(id)"
      >
        <ion-icon slot="start" :src="share" class="text-black dark:text-white text-xl" />
        Share
      </ion-item>
    </ion-list>
  </ion-popover>
  <div v-if="showShare" class="fixed top-0 right-2 w-full z-50">
    <div class="bg-white m-2 dark:bg-black p-4 w-full space-y-2 rounded-lg">
      <h2>Copy this link:</h2>
      <div class="p-2">
        <input
          ref="popoverInput"
          class="w-full text-[0.8rem] rounded-lg text-black"
          :value="`https://app.${domain}/post/${id}`"
          readonly
          @click.stop.prevent="selectLinkText"
        />
      </div>
      <button @click.stop.prevent="closeShare">Close</button>
    </div>
  </div>
  <ion-toast
    :is-open="toastOpen"
    :message="toastMessage"
    :duration="toastDuration"
    :color="toastColor"
    @didDismiss="closeToast"
  ></ion-toast>
</template>
<script setup>
import { IonIcon, IonItem, IonList, IonPopover, IonToast } from "@ionic/vue";
import { ellipsisHorizontalSharp, trashOutline } from "ionicons/icons";
import { usePostStore } from "@/stores/post";
import { useAuthStore } from "@/stores/auth";
import { useRouter } from "vue-router";
import { ref, onBeforeUnmount } from "vue";
import pin from "@/assets/pin.svg";
import share from "@/assets/parler/share.svg";
const authStore = useAuthStore();
const props = defineProps({
  id: String,
  userId: String,
  repostUserId: String,
  repostId: String,
  postType: String,
  isDetail: Boolean,
});

const emits = defineEmits(["click"]);

const postStore = usePostStore();
const popoverOpen = ref(false);
const deletePostEvent = ref(null);
const pinnedPost = ref(null);
const username = authStore.currentUser.username;
const currId = authStore.currentUser.ulid;
const router = useRouter();
const popoverInput = ref(null);
const showShare = ref(false);

// Toast related states
const toastOpen = ref(false);
const toastMessage = ref("");
const toastDuration = ref(2000);
const toastColor = ref("");

const apiHost = import.meta.env.VITE_API_HOST;
const domain = apiHost.substring(apiHost.indexOf(".") + 1);

// Function to attempt copying and show popover on failure
function copyPostUrlAndShowToast(postId) {
  const postUrl = `https://app.${domain}/post/${postId}`;

  navigator.clipboard
    .writeText(postUrl)
    .then(() => {
      showToast("Link copied to clipboard!", "success");
    })
    .catch((err) => {
      console.error("Failed to copy:", err);
      // Show the popover if clipboard API fails
      showShare.value = true;
      popoverOpen.value = false;
    });
}

function showToast(message, color) {
  toastMessage.value = message;
  toastColor.value = color;
  toastOpen.value = true;
}

function closeShare() {
  showShare.value = false;
}

function closeToast() {
  toastOpen.value = false;
}

function selectLinkText() {
  if (popoverInput.value) {
    popoverInput.value.select();
  }
}

async function openPopover(e) {
  deletePostEvent.value = e;
  if (props.userId === currId) {
    pinnedPost.value = await postStore.getPinnedPost(username);
  }
  popoverOpen.value = true;
}
async function pinPost(id) {
  if (id === pinnedPost.value) {
    postStore.pinPost(id, "delete");
  } else {
    if (pinnedPost.value) {
      const previousPin = pinnedPost.value;
      postStore.pinPost(previousPin, "delete");
    }
    postStore.pinPost(id, "add");
  }
}
async function deletePost(id) {
  if (props.repostId && props.repostUserId === authStore.currentUser.ulid) {
    await postStore.deletePost(props.repostId);
    popoverOpen.value = false;
    return;
  }
  await postStore.deletePost(id);
  popoverOpen.value = false;

  if (props.isDetail) {
    router.push(`/feed`);
  }
}

onBeforeUnmount(() => {
  popoverOpen.value = false;
  deletePostEvent.value = null;
  pinnedPost.value = null;
});
</script>
