<template>
  <div
    v-if="showToolBar && showHint && firstPost"
    class="absolute bottom-15 left-0 right-0 z-20 p-5"
  >
    <!-- Guide user to create post -->
    <div
      class="fixed bottom-[6rem] p-5 left-[15%] w-1/3 rounded-lg rounded-br-none shadow-xl bg-white text-black dark:bg-black dark:text-white"
    >
      <p class="font-semibold text-center text-sm">
        Click here to create your first post!
      </p>
      <!-- Pointing to create pop button -->
      <ion-icon
        class="absolute -bottom-3 -right-3 text-4xl text-red-500 rotate-45"
        :icon="caretForwardCircleSharp"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { IonIcon } from "@ionic/vue";
import { caretForwardCircleSharp } from "ionicons/icons";
import { useAuthStore } from "@/stores/auth";

// Define reactive variable
const showHint = ref(false);
const showTimeout = ref(null);
let hideTimeout = null;

onMounted(() => {
  // Show the hint after 4 seconds
  showTimeout.value = setTimeout(() => {
    showHint.value = true;
  }, 4000);

  hideTimeout = setTimeout(() => {
    showHint.value = false;
  }, 5000); // Hide the hint after 10 seconds
});

defineProps({
  showToolBar: {
    type: Boolean,
    required: true,
    default: true,
  },
});

const authStore = useAuthStore();

const firstPost = ref(authStore.currentUser.postCount === 0);

onBeforeUnmount(() => {
  if (showTimeout.value) {
    clearTimeout(showTimeout.value);
  }
  if (hideTimeout) {
    clearTimeout(hideTimeout);
  }
});
</script>

<style scoped>
/* You can add any additional Tailwind CSS styles here if required */
.fixed {
  pointer-events: none;
}
</style>
