<template>
  <ion-modal
    :is-open="isOpened"
    :initial-breakpoint="0.75"
    :breakpoints="[0, 0.25, 0.5, 0.75, 1]"
    @willDismiss="closeInviteModal"
  >
    <ion-header>
      <ion-toolbar>
        <ion-title>Invite</ion-title>
        <!-- Close button -->
        <ion-buttons slot="end">
          <ion-button @click="closeInviteModal">
            <ion-icon :icon="closeCircle" />
          </ion-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>
    <ion-content class="ion-padding">
      <label class="block font-bold mb-2" v-if="referralLink">Invite via Link</label>
      <div class="flex justify-center items-center space-x-2">
        <input
          v-if="referralLink"
          v-model="referralLink"
          readonly
          class="appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline h-8"
          @click.stop.prevent="selectLinkText"
          ref="inviteLink"
        />
        <button
          class="bg-primary text-white px-2 rounded-lg h-8"
          @click="getReferralLink"
        >
          {{ referralLink ? "Copy" : "Get Referral Link" }}
        </button>
      </div>

      <form @submit.prevent="login" class="pt-6 pb-8 mb-4">
        <div class="mb-4">
          <label for="email" class="block font-bold mb-2">Invite via Email</label>
          <div class="flex justify-center items-center space-x-2">
            <input
              type="email"
              id="email"
              v-model="email"
              required
              class="appearance-none border rounded w-full py-2 px-3 text-gray-700 bg-white leading-tight focus:outline-none focus:shadow-outline h-8"
            />
            <button
              type="submit"
              class="px-3 h-8 rounded-lg flex justify-center items-center text-white"
              :class="validEmail ? 'bg-primary' : 'bg-gray-700'"
              @click="invite"
              :disabled="!validEmail"
            >
              <ion-icon :icon="mailSharp" />
            </button>
          </div>
        </div>
        <div class="mb-6 flex flex-col">
          <div
            v-if="inviteError || inviteSuccess"
            class="rounded-lg text-black text-md mb-2"
            :class="{
              'bg-green-200': inviteSuccess,
              'bg-red-200': inviteError,
            }"
          >
            <p class="p-2 text-center">{{ inviteMessage }}</p>
          </div>
        </div>
      </form>
    </ion-content>
  </ion-modal>
</template>

<script setup>
import {
  IonButton,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar,
  toastController,
  IonButtons,
} from "@ionic/vue";
import { mailSharp, closeCircle } from "ionicons/icons";
import { ref, computed, onBeforeUnmount } from "vue";
import { apiBackendAuthAxios } from "@/axiosAuth";

defineProps({
  isOpened: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(["closeInvite", "showToash"]);
const email = ref("");
const inviteError = ref(false);
const inviteSuccess = ref(false);
const inviteMessage = ref(false);
const referralLink = ref("");
const inviteLink = ref(null);

const validEmail = computed(() => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return email.value && emailRegex.test(email.value);
});

function closeInviteModal() {
  email.value = "";
  inviteMessage.value = "";
  referralLink.value = "";
  emit("closeInvite");
}

function selectLinkText() {
  if (inviteLink.value) {
    inviteLink.value.select();
  }
}

async function invite() {
  // Regular expression for email format validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  try {
    if (!email.value || !emailRegex.test(email.value)) {
      return; // If email is not provided or doesn't match the format exit the function early
    }
    const response = await apiBackendAuthAxios.post("/user/inviteByEmail", {
      email: email.value,
    });

    if (response.status === 200 || response.status === 202) {
      // API request was successful
      inviteSuccess.value = true;
      inviteMessage.value =
        "Invitation sent successfully! Would you like to invite another person?";
      emit("showToash", {
        message: "Invitation sent successfully!",
        type: "success",
      });

      email.value = "";
    } else {
      // Handle the case where the response is not successful
      inviteError.value = true;
      inviteMessage.value = "Uh oh, something went wrong, try again";
      emit("showToash", {
        message: "Uh oh, something went wrong, try again",
        type: "error",
      });
    }
  } catch (error) {
    handleInviteError(error);
  }
}

function handleInviteError(error) {
  if (
    error.response &&
    error.response.status === 400 &&
    error.response.data.message === "User already invited"
  ) {
    inviteError.value = true;
    inviteMessage.value = error.response.data.message;
    emit("showToash", {
      message: error.response.data.message,
      type: "error",
    });
  } else if (error.response && error.response.status === 403) {
    inviteError.value = true;
    inviteMessage.value = error.response.data.error
      ? error.response.data.error
      : "There was an error sending the invite, please try again.";
    emit("showToash", {
      message: error.response.data.error
        ? error.response.data.error
        : "There was an error sending the invite, please try again.",
      type: "error",
    });
  } else {
    // General error handling
    inviteError.value = true;
    inviteMessage.value = "An unexpected error occurred. Please try again later.";
    emit("showToash", {
      message: "An unexpected error occurred. Please try again later.",
      type: "error",
    });
  }
}

const getReferralLink = async () => {
  if (referralLink.value) {
    addToClipboard();
    return;
  }
  try {
    const response = await apiBackendAuthAxios.get("/user/referral-link");
    referralLink.value = response.data.referralLink || "Error getting referral link";

    addToClipboard();
  } catch (error) {
    console.error("Error getting referral link:", error);
    showToast("Error getting referral link. Please try again.", "danger");
  }
};

const addToClipboard = async () => {
  navigator.clipboard
    .writeText(referralLink.value)
    .then(() => {
      showToast("Referral link copied to clipboard!", "success");
    })
    .catch((err) => {
      console.error("Failed to copy:", err);
      showToast("Failed to copy link. Please try again.", "warning");
    });
};

const showToast = async (message, color) => {
  const toast = await toastController.create({
    message: message,
    duration: 2000,
    color: color,
  });
  return toast.present();
};

onBeforeUnmount(() => {
  email.value = "";
  inviteError.value = false;
  inviteSuccess.value = false;
  inviteMessage.value = "";
  referralLink.value = "";
});
</script>
<style scoped>
.bg-primary {
  background-color: var(--primary-color);
}
</style>
