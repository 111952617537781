<template>
    <ion-card class="shadow-none rounded-md ion-no-margin border-b-4 border-zinc-50 dark:border-b-4 dark:border-black" style="width: 100%">
        <ion-card-header>
            <div :key="`emptyRepost-${id}`">
                <div class="-mt-3 items-center mb-2 -ml-1 font-bold cursor-pointer select-none">
                    <div v-if="!(repostUsername === 'parlerdiscover' && globalStore.currentFeed === 'influencers') || isCurrentUserEmployee" 
                        class="flex justify-start space-x-1 px-1 items-center font-bold cursor-pointer select-none"
                        @click.stop.prevent="navigateToProfile(repostUsername)"
                    >
                        <ion-icon  :src="repostIcon" class="link align-middle text-lg" /> 
                        <span>reposted by</span>
                        <span class="link cursor-pointer">@{{ repostUsername }}</span>
                    </div>
        
                    <span class="absolute top-4 right-4">
                        <PostMenuForOwner v-if="repostUserId === authStore.currentUser.ulid" :id="id" :user-id="userId"
                            :repost-user-id="repostUserId" :post-type="postType" :repost-id="repostId" />
                        <PostMenu v-else :id="id" :user-id="userId" :username="repostUsername" :repost-user-id="repostUserId"
                            :post-type="postType" :parentId="parentId" :repostUsername="repostUsername" :repost-id="repostId" />
                    </span>
                </div>
            </div>
        </ion-card-header>
        <ion-card-content class="flex flex-col justify-center items-center  border dark:border-zinc-700 rounded-2xl mx-4 mb-2 p-4">
              <ion-icon :icon="alertCircle" class="text-primary pt-4 text-6xl" />
            <p>Post Unavailable</p>
        </ion-card-content>
    </ion-card>
</template>
<script setup>

import { computed, ref, onMounted, onBeforeUnmount } from "vue";
import { useUserStore } from "@/stores/users.js";
import { useAuthStore } from "@/stores/auth.js";
import { useGlobalStore } from "@/stores/global.js";
import { IonIcon, IonCard, IonCardContent, IonCardHeader } from "@ionic/vue";
import { alertCircle } from "ionicons/icons";
import { useRouter } from "vue-router";
import PostMenuForOwner from "@/components/posts/PostMenuForOwner.vue";
import PostMenu from "@/components/posts/PostMenu.vue";
import repostIcon from "@/assets/parler/repost_icon.svg";

const props = defineProps({
  id: String,
  createdAt: String,
  userId: String,
  repostUserId: String,
  repostId: String,
  postType: String,
  views: Number,
  additionalReposts: Number,
  repostUsernames: Array,
  quoted: {
    type: Boolean,
    default: false,
  },
  isCommentDetails: {
    type: Boolean,
    default: false,
  },
  depth: {
    type: Number,
    default: 0,
  },
  isEmptyRepost: {
    type: Boolean,
    default: false,
  },
  isDetail: {
    type: Boolean,
    default: false,
  },
  feedType: {
    type: String,
  },
  isCreatePost: {
  type: Boolean,
  default: false,
  },
  parentId: String,
});

const router = useRouter();
const userStore = useUserStore();
const authStore = useAuthStore();
const globalStore = useGlobalStore();
const username = computed(() => userStore.getUsername(props.userId));
const repostUsername = computed(() => userStore.getUsername(props.repostUserId));

const isCurrentUserEmployee = computed(() => {
  return (
    authStore.currentUser.badges &&
    authStore.currentUser.badges.includes("employee")
  );
});

const navigateToProfile = (fnUsername) => {

  if (fnUsername === 'Loading...') {
    if (fnUsername === username.value) {

      userStore.fetchUsersByIds([props.userId]);
    } else if (fnUsername === repostUsername.value) {
      userStore.fetchUsersByIds([props.repostUserId]);
    }
    return;
  }

  const encodedUsername = encodeURIComponent(fnUsername);

  router.push(`/${encodedUsername}`);


};
// Update timeAgo message every second
let intervalId;
onMounted(() => {

});

onBeforeUnmount(() => {

  if (intervalId) {
    clearInterval(intervalId);
  }

});
</script>
<style scoped>
.link {
  color: var(--tertiary-color);
  /* Additional styling as needed */
}

.card-content-ios p {
  /* Reset font size and line-height */
  font-size: 0.75rem;
  line-height: inherit;
}

.text-primary {
  color: var(--primary-color);
}
</style>
